/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// core components
import Header from "components/Headers/Header.js";
import Pagination from "components/Pagination/index.js";
import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from "react-table-6";
// reactstrap components
import { Button, Card, CardHeader, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, Badge } from "reactstrap";
import { LINK_API, LOADING } from "constants/API.js"
import SweetAlert from 'react-bootstrap-sweetalert';
import "./styles/table.css";
import Axios from "axios";
import 'react-table-6/react-table.css'
class Tables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            openModal: false,
            alert: null
        }
        this.genTable = this.genTable.bind(this)
        this.handleAddVote = this.handleAddVote.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleStop = this.handleStop.bind(this)
        this.hideAlert = this.hideAlert.bind(this)
        this.handleFailure = this.handleFailure.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleFinishVote = this.handleFinishVote.bind(this)
        this.handleOneVoting = this.handleOneVoting.bind(this)
    }
    componentDidMount() {
        this.props.getVoteList();
        if (this.props.voteList.data) {
            this.genTable(this.props.voteList.data)
        }
        if (this.props.userInfo.data) {
            Axios.defaults.headers.common.Authorization = this.props.userInfo.data.token
        }
        this.props.savePath("")
    }
    componentDidUpdate(preProps) {
        if (this.props.voteList.data !== preProps.voteList.data) {
            if (this.props.voteList.data) {
                this.genTable(this.props.voteList.data)
            }
        }
    }
    handleChangePage(id) {
        this.props.history.push("/admin/edit/" + id)
    }
    handleAddVote() {
        this.props.history.push("/admin/add")
    }
    handleSuccess() {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block" }}
                    title={this.props.t('success')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                >
                    <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
                </SweetAlert>
            )
        })
    }
    handleFailure() {
        this.setState({
            alert: (
                <SweetAlert
                    error
                    style={{ display: "block" }}
                    title={this.props.t('failure')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                    showCancel={false}
                >
                    <Button color="danger" onClick={this.hideAlert}>{this.props.t('close')}</Button>
                </SweetAlert>
            )
        })
    }
    handleProcessing() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    customClass='bg-dark-popup'
                    style={{ display: "block" }}
                    title={this.props.t('processing')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                >
                    <img alt="loading" src={LOADING} />
                </SweetAlert>
            )
        })
    }
    handleStop(id, status) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title={status === 1 ? this.props.t('addNewVote.finish') : this.props.t('addNewVote.continue')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                    showCancel={false}
                >
                    <div style={{ display: "flex" }} className="flex-column">
                        {id}
                        <div style={{ display: "flex", marginTop: "15px" }} className="flex-row align-items-center text-center justify-content-center">
                            <Button color="danger" onClick={this.hideAlert}>{this.props.t('cancle')}</Button>
                            <Button color="primary" onClick={() => this.handleFinishVote(id)}>{this.props.t('confirm')}</Button>
                        </div>

                    </div>
                </SweetAlert>
            )
        })

    }
    handleFinishVote(id) {
        this.handleProcessing();
        const data = {
            "vote_id": id
        }
        Axios.put(LINK_API.CHANGE_STATUS_VOTE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.handleSuccess();
                }
            })
            .catch(err => {
                this.handleFailure()
            })

    }
    hideAlert() {
        this.setState({
            alert: null
        })
        this.props.getVoteList();
    }
    handleOneVoting(id) {
        this.props.history.push('/employTea/' + id)
    }
    genTable(data) {
        this.setState({
            data: data.map((item, index) => ({
                ...item,
                total: item.numOfCandidate,
                startTime: moment(item.startTime).format("DD/MM/YYYY hh:ss"),
                endTime: moment(item.endTime).format("DD/MM/YYYY hh:ss"),
                status: item.status === 0 ?
                    (<Badge color="" className="badge-dot mr-4">
                        <i className="bg-danger" />
                        {this.props.t('addNewVote.finished')}
                    </Badge>) :
                    (<Badge className="badge-dot mr-4">
                     
                        {this.props.t('addNewVote.onProcess')}
                    </Badge>),
                actions: (
                    <UncontrolledDropdown>
                        <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                        >
                            <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                                onClick={() => this.props.history.push("/voting/" + item.vote_id)}
                            >
                                <i className="ni ni-bullet-list-67" />
                                {this.props.t('voteList.detail')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={() => this.handleChangePage(item.vote_id)}
                            >
                                <i className="ni ni-ruler-pencil" />
                                {this.props.t('voteList.edit')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={() => this.handleStop(item.vote_id, item.status)}
                            >
                                {item.status === 1 ? <i className="ni ni-fat-remove" /> : <i className="ni ni-curved-next" />}
                                {item.status === 1 ? this.props.t('addNewVote.finish') : this.props.t('addNewVote.continue')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={() => this.handleOneVoting(item.vote_id)}
                            >
                                <i className="ni ni-box-2" />
                                Random
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )
            }))
        })
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow-card-dark px-2">
                                <CardHeader className="border-0 bg-transparent overflow-hidden">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0 text-white">{t('voteList.name')}</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color='primary'
                                                className='btn btn-gradient-info'
                                                href="#pablo"
                                                onClick={this.handleAddVote}
                                                // size="sm"
                                            >
                                                {t('add')}
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <ReactTable
                                    data={this.state.data}
                                    previousText="<"
                                    nextText=">"
                                    rowsText="hàng"
                                    ofText="của"
                                    pageText="Trang"
                                    noDataText="Không có dữ liệu"
                                    columns={[
                                        {
                                            Header: t('voteList.title'),
                                            accessor: "name",
                                            filterable: false
                                        },
                                        {
                                            Header: t('voteList.start'),
                                            accessor: "startTime",
                                            filterable: false
                                        },
                                        {
                                            Header: t('voteList.end'),
                                            accessor: "endTime",
                                            filterable: false
                                        },
                                        {
                                            Header: t('voteList.tx'),
                                            accessor: "tx_id",
                                            filterable: false
                                        },
                                        {
                                            Header: t('voteList.number'),
                                            accessor: "total",
                                            filterable: false
                                        },
                                        {
                                            Header: t('addNewVote.status'),
                                            accessor: "status",
                                            filterable: false
                                        },
                                        {
                                            Header: t('actions'),
                                            accessor: "actions",
                                            filterable: false
                                        }

                                    ]}
                                    defaultPageSize={5}
                                    sortable={false}
                                    resizable={false}
                                    className="-striped -highlight"
                                    PaginationComponent={Pagination}
                                />


                            </Card>
                        </div>
                    </Row>
                    {this.state.alert}
                </Container>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        voteList: state.voteList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getVoteList: () => dispatch({ type: "GET_VOTE_LIST" }),
        savePath: (data) => dispatch({ type: "SAVE_PATH_WEBSITE", data: data }),

    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Tables));
