import React from "react";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { withTranslation } from 'react-i18next';
import "./style.css";


function MyDropzone(...props) {
  const t = props[0].t;
  const listImages = props[0].list;
  const maxPic = props[0].max;
  const [addImage, setAdd] =  React.useState([])
  const [deleteImage, setDelete] =  React.useState([])
  const [count, setCount] = React.useState(listImages.length);
  const handleChangeStatus = async ({ meta, file }, status) => {
 
    if(status === "done"){
     
      const link = meta.previewUrl;
      creatFile(link, meta.name)
    }
    if(status === "removed"){
      let temp_data = addImage.filter(item=> item.name !== meta.name)
      await setAdd(temp_data)
      props[0].onAdd(addImage)
    }
  }

  const handleData = async (data, name) => {
    let temp = addImage;
    temp.push({
      name: name, 
      src: data.result
    })
    props[0].onAdd(addImage)
  }
  const handleSubmit = async (files, allFiles) => {
    const result = await files.map(f => f.meta)
   creatFile(result[0].previewUrl);
  }
  const handleChange = (e) => {
  }
  const handleDelete =async (url) => {
   let temp = deleteImage;
   temp.push(url);
   await setDelete(temp)
   setCount(count-1)
   props[0].onDelete(deleteImage)
  }
  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    const list = listImages.map((item, key)=>(
      <div className="alignItems" key={key}>
        <div className="flexStart">
        <img alt="url" src={item.url} className="imageList"/>
        </div>
        <div className="flexEnd">
        <i className="ni ni-fat-remove text-red" style={{fontSize: "20px", cursor: "pointer"}} onClick={()=> handleDelete(item.url)}/>
        </div>
      </div>
    ))
    return (
      <div>
        <div className="containerList">
        {list}
        {previews}
        </div>
        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
        </div>
  
        {files.length > 0 && submitButton}
      </div>
    )
  }
  const creatFile = (link, name) => {
    var file_result = "";
    var xhr = new XMLHttpRequest();
    xhr.open('GET', link, true);
    xhr.responseType = 'blob';
    xhr.onload =  (e) =>  {
     
      e.preventDefault()
      var reader = new FileReader();
      var recoveredBlob = xhr.response;
      reader.onloadend =  () => {
        handleData(reader, name)
      };

      reader.readAsDataURL(recoveredBlob);
      file_result = (reader)
    };
    xhr.send();
    return file_result;
  }
  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      onChange={handleChange}
      accept="image/*"
      styles={{ dropzone: { minHeight: 100, maxHeight: 100 } }}
      SubmitButtonComponent= {null}
      inputWithFilesContent={t('add')}
      maxFiles={maxPic}
      inputContent={t('addNewVote.dragFile')}
      LayoutComponent={Layout}

    />
  )
}
export default withTranslation('common')(MyDropzone);