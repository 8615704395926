import AddVote from "views/examples/addVote.js";
import EditVote from "views/examples/editVote.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Tables from "views/examples/voteList.js";
var routes = [
  // {
  //   path: "/index",
  //   name: "dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  {
    path: "/list",
    name: "list",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  {
    path: "/add",
    name: "addVote",
    icon: "ni ni-bullet-list-67 text-red",
    component: AddVote,
    layout: "/admin",
    hide: true
  },
  {
    path: "/edit/:id",
    name: "editVote",
    icon: "ni ni-bullet-list-67 text-red",
    component: EditVote,
    layout: "/admin",
    hide: true
  },

  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    hide: true
  },
 
];
export default (routes) ;
