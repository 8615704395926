/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios"
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    InputGroup,
    Alert
} from "reactstrap";
// core components
import UserHeader from "components/Headers/Header.js";
import { withTranslation } from 'react-i18next';
import ReactTable from "react-table-6";
import Pagination from "components/Pagination/index.js";
import { LINK_API, LOADING } from "constants/API.js"
import SweetAlert from 'react-bootstrap-sweetalert';
import SuccessImg from "assets/img/success.gif"

import "./styles/table.css"
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameVote: "",
            nameVoteValid: false,
            nameVoteInValid: false,

            desVote: "",
            desVoteValid: false,
            desVoteInValid: false,

            candidates: [],
            checkCandidate: true,
            openModal: false,
            alert: null,
        }
        this.handleProcessData = this.handleProcessData.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleAddNewCand = this.handleAddNewCand.bind(this);
        this.handleCreateVote = this.handleCreateVote.bind(this)
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleFailure = this.handleFailure.bind(this);
        this.handleSendRawCreateVote = this.handleSendRawCreateVote.bind(this);
        this.handleAddCandidate = this.handleAddCandidate.bind(this);
        this.handelSendRawAddCandidate = this.handelSendRawAddCandidate.bind(this);
    }
    componentDidMount() {
        if (this.props.userInfo.data) {
            axios.defaults.headers.common.Authorization = this.props.userInfo.data.token
        }
    }
    componentDidUpdate(preProps) {

    }
    hideAlert() {
        this.setState({
            alert: null
        })
    }
    handleChangePage() {
        this.props.history.push(`/admin/edit/${this.state.id_of_vote}`)
    }
    handleSuccess() {
        this.setState({
            alert: (
                <SweetAlert
                    // success
                    customClass='bg-dark-popup'
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={this.handleChangePage}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                    showCancel={false}
                >
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img src={SuccessImg} alt='success' style={{ height: '200px', width: '200px' }} />
                        <span className='text-primary success-title'>{this.props.t("success")}</span>

                        <Button color="primary" className='btn-gradient-info' onClick={this.handleChangePage}>{this.props.t('confirm')}</Button>
                    </div>
                </SweetAlert>
            )
        })
    }
    handleFailure() {
        this.setState({
            alert: (
                <SweetAlert
                    error
                    style={{ display: "block" }}
                    title={this.props.t('failure')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={true}
                >
                </SweetAlert>
            )
        })
    }
    handleAddCandidate(id) {
        const data = {
            "candidates": this.state.candidates,
            "vote_id": id,
            "vote_type": 0
        }
        axios.post(LINK_API.ADD_CANDIDATE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.handelSendRawAddCandidate(res.data.data)
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handelSendRawAddCandidate(data) {
        var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.pass);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2, privateKey.length);
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
        const tx1 = new EthereumTx(data.configVoteRawTx, { chain: 4 });
        tx1.sign(Buffer_privatekey);
        const tx2 = new EthereumTx(data.addCandidateRawTx, { chain: 4 });
        tx2.sign(Buffer_privatekey);
        const data_raw = {
            ...data,
            configVoteRawTx: '0x' + tx1.serialize().toString('hex'),
            addCandidateRawTx: '0x' + tx2.serialize().toString('hex'),
            "vote_type": 0
        }
        axios.post(LINK_API.SEND_RAW_CANDIDATE, data_raw)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.handleSuccess();
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleCreateVote() {
        // if(!this.state.nameVote || !this.state.desVote || this.state.candidates.length === 0){
        //     if(!this.state.nameVote){
        //         this.setState({
        //             nameVoteInValid: true,
        //             nameVoteValid: false
        //         })
        //     }
        //     if(!this.state.desVote){
        //         this.setState({
        //             desVoteInValid: true,
        //             desVoteValid: false
        //         })
        //     }
        //     if(this.state.candidates.length === 0){
        //         this.setState({
        //             checkCandidate: false
        //         })
        //     }
        //     return;
        // }
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    customClass='bg-dark-popup'
                    style={{ display: "block" }}
                    title={<span className='text-white'>{this.props.t('processing')}</span>}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                >
                    <img alt="loading" src={LOADING} />
                </SweetAlert>
            )
        })
        const data = {
            "name": this.state.nameVote,
            "symbol": "TECH",
            "description": this.state.desVote,
            "tag": "2021",
        }
        axios.post(LINK_API.CREATE_VOTE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    console.log('Check')
                    this.handleSendRawCreateVote(res.data.data)
                } else {
                    this.handleFailure()
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleSendRawCreateVote(data) {
        console.log('data', this.props.userInfo)
        var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.pass);
        console.log('bytes', bytes)
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        console.log('privateKey', privateKey)
        const privatekey_slice = privateKey.substring(2, privateKey.length);
        console.log('privatekey_slice', privatekey_slice)
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(data.rawTx, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        const data_raw = {
            ...data,
            rawTx: rawTx
        }
        axios.post(LINK_API.SEND_RAW_CREATE_VOTE, data_raw)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.setState({
                        id_of_vote: res.data.data.vote_id
                    })
                    if (this.state.candidates.length === 0) {
                        console.log('Check ')
                        this.handleSuccess();
                    } else {
                        setTimeout(this.handleAddCandidate(res.data.data.vote_id), 2000)
                    }
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleChange = name => async event => {
        const valid = name + "Valid";
        const inValid = name + "InValid"
        await this.setState({
            [name]: event.target.value,
            [valid]: event.target.value ? true : false,
            [inValid]: event.target.value ? false : true
        })
    }
    async handleAddNewCand() {
        let data = this.state.candidates;
        await data.push({
            "name": this.state.candName,
            "description": this.state.candDes
        })
        this.setState({
            candidates: data,
            openModal: false,
            candName: "",
            candDes: "",
        })
    }
    handleProcessData(data) {
        this.setState({
            username: data.name,
            phone: data.phone,
            email: data.email
        })
    }
    handleCloseModal() {
        this.setState({
            openModal: false
        })
    }
    handleOpenModal() {
        this.setState({
            openModal: true
        })
    }

    handleToggle() {
        this.setState({
            openModal: !this.state.openModal
        })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <UserHeader />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="shadow-card-dark">
                                <CardHeader className="bg-transparent border-0">
                                    <Row className="align-items-center">
                                        <Col xs="12">
                                            <h3 className="mb-0 text-white">{t('addNewVote.info')}</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form className='form-login'>
                                        <div>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className={this.state.nameVoteValid ? "has-success-inside" : this.state.nameVoteInValid ? "has-danger-inside" : ""}>
                                                        <label
                                                            className="form-control-label text-white"
                                                            htmlFor="input-username"
                                                        >
                                                            {t('addNewVote.name')}
                                                        </label>
                                                        <Input
                                                            className={this.state.nameVoteValid ? "is-valid" : this.state.nameVoteInValid ? "is-invalid" : "form-control-alternative"}
                                                            id="input-username"
                                                            placeholder={t('addNewVote.name')}
                                                            type="text"
                                                            value={this.state.nameVote || ''}
                                                            onChange={this.handleChange("nameVote")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <FormGroup className={this.state.desVoteValid ? "has-success-inside" : this.state.desVoteInValid ? "has-danger-inside" : ""}>
                                                <label
                                                    className="form-control-label text-white"
                                                    htmlFor="input-username">{t('profile.about')}</label>
                                                <Input
                                                    className={this.state.desVoteValid ? "is-valid" : this.state.desVoteInValid ? "is-invalid" : "form-control-alternative"}
                                                    placeholder={t('addNewVote.fewDes')}
                                                    rows="4"
                                                    type="textarea"
                                                    value={this.state.desVote || ''}
                                                    onChange={this.handleChange("desVote")}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <div className="col">
                            <Card className="shadow-card-dark px-2">
                                <CardHeader className="border-0 bg-transparent overflow-hidden">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0 text-white">{t('addNewVote.candList')}</h3>
                                        </div>
                                        <div >
                                            {!this.state.checkCandidate ? <Alert color="danger">
                                                {t('addNewVote.notiCand')}
                                            </Alert> : null}
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                                className='btn-gradient-info'
                                                href="#pablo"
                                                onClick={this.handleOpenModal}

                                            >
                                                {t('add')}
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <ReactTable
                                    data={this.state.candidates}
                                    previousText="<"
                                    nextText=">"
                                    rowsText="hàng"
                                    ofText="của"
                                    pageText="Trang"
                                    noDataText={t('addNewVote.noCand')}
                                    columns={[
                                        {
                                            Header: t('addNewVote.candName'),
                                            accessor: "name",
                                            filterable: false
                                        },
                                        {
                                            Header: t('addNewVote.des'),
                                            accessor: "description",
                                            filterable: false
                                        },
                                    ]}
                                    defaultPageSize={5}
                                    sortable={false}
                                    resizable={false}
                                    className="-striped -highlight"
                                    PaginationComponent={Pagination}
                                />


                            </Card>
                        </div>
                    </Row>

                    <Modal zIndex={2000} centered={true} backdrop={true} backdropClassName='modal-backdrop' isOpen={this.state.openModal} toggle={this.handleToggle} className=' overflow-hidden'>
                        <ModalHeader className='bg-dark-modal' toggle={this.handleToggle}><span className='text-white'>{t('addNewVote.newCand')}</span></ModalHeader>
                        <ModalBody className='bg-dark-modal'>
                            <Form role="form">
                                <div>
                                    <Row>
                                        <Col lg="12">
                                            <Form className='form-login'>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label text-white"
                                                        htmlFor="input-username"
                                                    >
                                                        {t('addNewVote.candName')}
                                                    </label>
                                                    <InputGroup className="input-group-alternative mb-3">
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-username"
                                                            placeholder={t('addNewVote.candName')}
                                                            type="text"
                                                            value={this.state.candName || ''}
                                                            onChange={this.handleChange("candName")}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Form>

                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Form className='form-login'>
                                        <FormGroup>
                                            <label
                                                className="form-control-label text-white"
                                                htmlFor="input-username">{t('profile.about')}</label>
                                            <InputGroup className="input-group-alternative mb-3">
                                                <Input
                                                    className="form-control-alternative"
                                                    placeholder={t('addNewVote.fewDesCand')}
                                                    rows="4"
                                                    type="textarea"
                                                    value={this.state.candDes || ''}
                                                    onChange={this.handleChange("candDes")}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter className='bg-dark-modal'>
                            <Button color="primary" className='btn-gradient-info' onClick={this.handleAddNewCand}>{t('add')}</Button>{' '}
                            <Button color="danger" onClick={this.handleCloseModal}>{t('cancle')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Row style={{ marginTop: "30px" }}>
                        <Col lg="12" className='text-right pb-3'>
                            <Button color="primary" className='btn-gradient-info' onClick={this.handleCreateVote}>{t('confirm')}</Button>
                        </Col>
                    </Row>
                    {this.state.alert}
                </Container>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord })

    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Profile));
