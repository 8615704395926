const style = theme => ({
    avatar: {
        borderRadius: "50%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "48px",
        width: "48px"
    }
})
export default style;