import React from "react";
import "./index.css";
import Header from "../yearend/header"
import { Container, Row, Card, ListGroup, ListGroupItem, Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { connect } from "react-redux"
import Pagination from "./pagination"

class Yearend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: [],
            totalPage: 0,
            currentPage: 0,
            modal: false,
            step: 5
        }
        this.handleRefresh = this.handleRefresh.bind(this)
        this.toggle = this.toggle.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this)

    }
    componentDidMount() {
        this.props.cleanDataUser();
        this.props.getListVote('0xa9c72d675595063db6e9e50feacd8b4a36078d77');
        if (this.props.listVote.data) {
            this.setState({
                list: [...this.props.listVote.data],
                totalPage: this.props.listVote.data.length / 5
            })
        }
        document.body.classList.add("bg-default");
        let timer = setTimeout(() => {
            this.handleRefresh();
            clearTimeout(timer)
        }, 2000)

    }
    componentDidUpdate(nextProps) {
        if (nextProps.listVote !== this.props.listVote) {
            if (nextProps.listVote.data) {
                this.setState({
                    list: nextProps.listVote.data,
                    totalPage: nextProps.listVote.data.length / 5
                })
            }
        }
    }
    componentWillUnmount() {
        document.body.classList.remove("bg-default");
    }
    toggle(tx) {
        this.setState({
            modal: !this.state.modal,
            currenTx: tx
        })
    }
    handleRefresh() {
        this.props.getListVote('0xa9c72d675595063db6e9e50feacd8b4a36078d77')
    }
    handleChangePage(page) {
        this.setState({
            currentPage: page
        })
    }
    render() {
        return (
            <>
                <div className="main-content">
                    <Header />
                    <div className="header bg-year-end py-7 py-lg-8">
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-default"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </div>
                    <Container className="mt-form-result pb-5">
                        <Row className="justify-content-center px-3">
                            <Card className='px-3 py-4 w-100'>
                                <div className='d-flex justify-content-between'>
                                    <span className='font-weight-bold text-dark'>Winner List</span>
                                    <Button color='primary' size='sm' onClick={this.handleRefresh}>Refresh</Button>
                                </div>
                                <div className='dropdown-divider'></div>
                                <p className='font-weight-bold text-dark'>Current winner</p>
                                {this.state.list && this.state.list[0] ?
                                    <ListGroupItem disabled tag="a" href="#" className='d-flex flex-column'>
                                        <span className='font-weight-bold text-dark  text-three-dots'>
                                            <span className='text-black-50'>Blockchain address:</span> {this.state.list[0].candidate.bc_address}</span>
                                        <span className='font-weight-bold text-dark'><span className='text-black-50'>Name:</span> {this.state.list[0].candidate.name}</span>
                                        <span className='font-weight-bold text-dark'><span className='text-black-50'>Phone number:</span> {this.state.list[0].candidate.description}</span>
                                        <div className='dropdown-divider'></div>
                                        <span className='font-weight-bold text-dark text-three-dots cursor-pointer' onClick={() => this.toggle(this.state.list[0].tx_id)}><span className='text-black-50'>Lucky Tx:</span> <span className='text-info font-weight-bold'>{this.state.list[0].tx_id}</span></span>
                                    </ListGroupItem>
                                    : null}
                                <div className='dropdown-divider mt-4'></div>
                                <p className='font-weight-bold text-dark'>List winner</p>
                                <ListGroup className='mt-3'>
                                    {this.state.list.map((item, idx) => (
                                        (this.state.currentPage) * 5 <= idx && idx < (this.state.currentPage + 1) * 5 ?
                                            <ListGroupItem disabled tag="a" href="#" className='d-flex flex-column'>
                                                <span className='font-weight-bold text-dark  text-three-dots'>
                                                    <span className='text-black-50'>Blockchain address:</span> {item.candidate.bc_address}</span>
                                                <span className='font-weight-bold text-dark'><span className='text-black-50'>Name:</span> {item.candidate.name}</span>
                                                <span className='font-weight-bold text-dark'><span className='text-black-50'>Phone number:</span> {item.candidate.description}</span>
                                                <div className='dropdown-divider'></div>
                                                <span className='font-weight-bold text-dark text-three-dots cursor-pointer' onClick={() => this.toggle(item.tx_id)}><span className='text-black-50'>Lucky Tx:</span> <span className='text-info font-weight-bold'>{item.tx_id}</span></span>
                                            </ListGroupItem> : null
                                    ))}
                                </ListGroup>
                                <Pagination onPageChange={this.handleChangePage} previousText='<' nextText='>' page={this.state.currentPage} pages={this.state.totalPage} />

                            </Card>
                        </Row>
                    </Container>
                    <Modal zIndex={2000} centered isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle} ><span className='font-weight-bold'>Verify Transaction</span></ModalHeader>
                        <ModalBody>
                            <iframe title="blockchain" style={{ height: '80vh', width: '100%' }} src={'https://blockchain.agridential.vn/tx/' + this.state.currenTx} />
                        </ModalBody>
                    </Modal>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        pathWebsite: state.pathWebsite.data,
        listVote: state.listVote,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cleanDataUser: () => dispatch({ type: "CLEAN_STORE" }),
        getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Yearend);
