import React from 'react'
import overviewStyle from './overviewStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { Button } from "reactstrap"
import "./member.css"
import Chart from "react-apexcharts";
import moment from 'moment';
import Header from "./topVote"
// reactstrap components
import {
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from 'reactstrap'
import ReactTable from "react-table-6";
import Pagination from "components/Pagination/index.js"
class overview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: "1",
            chartComponent: "",
            series: [{
                name: "Lượt bình chọn",
                data: [1380, 1200, 1100, 900, 780, 740, 670, 648, 630, 600]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        columnWidth: '55%',
                        barHeight: '70%',
                        borderRadius: 4,
                        dataLabels: {
                            position: 'top',
                            maxItems: 100,
                            hideOverflowingLabels: true,
                            orientation: "horizontal"
                        }
                        // endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: true,
                    //enabledOnSeries: undefined,
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        //return w.globals.labels[seriesIndex]+ ":  " + value
                        return value
                    },
                    textAnchor: 'start',
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        colors: undefined
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        padding: 4,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#fff',
                        opacity: 0.9,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                grid: {
                    borderColor: '#d2d2d2',
                    strokeDashArray: 7,
                    show: false
                },
                fill: {
                    colors: ['#0984e3', '#212b36'],
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0,
                        opacityFrom: 0.5,
                        opacityTo: 1,
                        stops: [0, 80, 100],
                        gradientToColors: ['#212b36'],
                        invertColors: true
                        //colorStops: ['#5b86e5']
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: 'left',
                        minWidth: 0,
                        maxWidth: 200,
                        style: {
                            colors: '#fff',
                            fontSize: '15px',
                            fontWeight: 700,
                            fontFamily: 'Muli',
                            cssClass: 'apexcharts-yaxis-label',
                        },
                        offsetX: 0,
                        offsetY: 0,
                        rotate: 0,
                        //formatter: (value) => { return val },
                    }
                },
                xaxis: {
                    categories: [
                    ],
                    type: 'category',
                    labels: {
                        show: false,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                            colors: [],
                            fontSize: '15px',
                            fontWeight: 400,
                            cssClass: 'apexcharts-xaxis-label',
                        },
                        offsetX: 0,
                        offsetY: 0,
                        format: undefined,
                        formatter: undefined,
                        datetimeUTC: true,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: "MMM 'yy",
                            day: 'dd MMM',
                            hour: 'HH:mm',
                        },
                    },
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: false,
                        borderType: 'solid',
                        color: '#78909C',
                        height: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                    tickAmount: undefined,
                    tickPlacement: 'between',
                    min: undefined,
                    max: undefined,
                    range: undefined,
                    floating: false,
                    position: 'bottom',
                    title: {
                        text: undefined,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-xaxis-title',
                        },
                    },
                    crosshairs: {
                        show: true,
                        width: 1,
                        position: 'back',
                        opacity: 0.9,
                        stroke: {
                            color: '#b6b6b6',
                            width: 0,
                            dashArray: 0,
                        },
                        fill: {
                            type: 'solid',
                            color: '#B1B9C4',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 1,
                            opacity: 0.4,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        formatter: undefined,
                        offsetY: 0,
                        style: {
                            fontSize: 0,
                            fontFamily: 0,
                        },
                    },
                }
            },
            data: []
        }
        this.toggle = this.toggle.bind(this);
        this.genTable = this.genTable.bind(this);
    }

    async componentDidMount() {
        if (this.props.pointAll) {
            if (this.props.pointAll.series) {
                this.setState({
                    series: this.props.pointAll.series
                })
            }
            if (this.props.pointAll.categories) {
                let temp = this.state.options;
                temp.xaxis.categories = this.props.pointAll.categories;
                await this.setState({
                    options: temp
                })
                await this.setState({
                    chartComponent: (
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            height="700"
                        />)
                })
            }
        }
        if (this.props.listVote) {
            if (this.props.listVote.data) {
                this.genTable(this.props.listVote.data)
            }
        }
    }
    async componentWillReceiveProps(nextProps) {

        if (this.props.pointAll !== nextProps.pointAll) {
            if (nextProps.pointAll.series) {
                this.setState({
                    series: nextProps.pointAll.series
                })
            }
            if (nextProps.pointAll.categories) {
                let temp = this.state.options;
                temp.xaxis.categories = nextProps.pointAll.categories;
                await this.setState({
                    options: temp,
                    chartComponent: ""
                })
                await this.setState({
                    chartComponent: (
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            height="700"
                        />
                    )
                })
            }
        }
        if (this.props.listVote !== nextProps.listVote) {
            if (nextProps.listVote.data) {
                this.genTable(nextProps.listVote.data)
            }
        }
    }
    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    genTable(data) {
        this.setState({
            data: data.map((item, index) => ({
                candidate: <p className="textTx">{item.candidate.name}</p>,
                age: 26,
                code: <p style={{ color: "blue !important", textDecoration: "underline", cursor: "pointer" }} onClick={() => item.network === "rinkeby" ? window.open("https://rinkeby.etherscan.io/tx/" + item.tx_id) : item.network === "ropsten" ? window.open("https://ropsten.etherscan.io/tx/" + item.tx_id) : window.open("https://blockchain.agridential.vn/tx/" + item.tx_id)} className="textTx">{item.tx_id}</p>,
                time: <p className="textTx">{moment(item.submit_time).format("DD/MM/YYYY hh:mm")}</p>,
                phone: <p className="textTx">{item.phone}</p>,
                confirm: item.network === "rinkeby" ? <Button onClick={() => window.open("https://rinkeby.etherscan.io/tx/" + item.tx_id)} color="primary" size="sm">Xác thực</Button> :
                    item.network === "ropsten" ? <Button onClick={() => window.open("https://ropsten.etherscan.io/tx/" + item.tx_id)} color="primary" size="sm">Xác thực</Button> :
                        <Button onClick={() => window.open("https://blockchain.agridential.vn/tx/" + item.tx_id)} color="primary" className='btn-gradient-info' size="sm">Xác thực</Button>
            }
            ))
        })
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <div className='title' style={{ textAlign: "center" }}>
                    <p className={classes.subTitle}>BẢNG XẾP HẠNG</p>
                    <hr className={classes.lineBreak} />
                    <p className={classes.listTitle}>KẾT QUẢ BẦU CHỌN</p>
                </div>
                <Header />
                <Container style={{ marginTop: "50px" }}>
                    <Row>
                        <Col xs={12} className={classes.noPaddingCol}>
                            <div className={classes.tabNavi}>
                                <div className={classes.wrapper}>
                                    <Nav id='tabs' role='tablist' tabs className={classes.centerItems}>
                                        <NavItem onClick={() => {
                                            this.toggle("1")
                                        }} className={this.state.activeTab === '1' ? classes.tabActiveRight : classes.tabRight}>
                                            <NavLink
                                                className={this.state.activeTab === '1' ? classes.active : classes.noActive}
                                                onClick={() => {
                                                    this.toggle('1')
                                                }}
                                            >
                                                Bình chọn mới nhất
                      </NavLink>
                                        </NavItem>
                                        <NavItem onClick={() => {
                                            this.toggle('2')
                                        }} className={this.state.activeTab === '2' ? classes.tabActiveLeft : classes.tabLeft}>
                                            <NavLink
                                                className={this.state.activeTab === '2' ? classes.active : classes.noActive}
                                                onClick={() => {
                                                    this.toggle('2')
                                                }}
                                            >
                                                Xếp hạng hiện tại
                      </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                            <TabContent activeTab={this.state.activeTab} className='text-center'>
                                <TabPane tabId='1' className={classes.tabPane}>
                                    <ReactTable
                                        data={this.state.data}
                                        previousText="<"
                                        nextText=">"
                                        rowsText="hàng"
                                        ofText="của"
                                        pageText="Trang"
                                        noDataText="Không có dữ liệu"
                                        columns={[
                                            {
                                                Header: "Thời gian",
                                                accessor: "time",
                                                filterable: false
                                            },
                                            {
                                                Header: "Số điện thoại",
                                                accessor: "phone",
                                                filterable: false
                                            },
                                            {
                                                Header: "Bình chọn cho",
                                                accessor: "candidate",
                                                filterable: false
                                            },
                                            {
                                                Header: "Transaction",
                                                accessor: "code",
                                                filterable: false
                                            },
                                            {
                                                Header: "Xác thực",
                                                accessor: "confirm",
                                                filterable: false
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        // showPaginationBottom={true}
                                        sortable={false}
                                        resizable={false}
                                        className="-striped -highlight"
                                        PaginationComponent={Pagination}
                                    />
                                </TabPane>
                                <TabPane tabId='2' className={classes.tabPane2}>
                                    <div className={classes.chart}>
                                        {this.state.chartComponent}
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        listVote: state.listVote,
        userInfo: state.userInfo,
        pointAll: state.chartData,
        pathWebsite: state.pathWebsite.data,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPointAll: (id) => dispatch({ type: "GET_POINT_ALL", id: id }),
        getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(overviewStyle)(overview))
