const overviewStyle = theme => ({
    subTitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#0984e3"
    },
    listTitle: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "30px !important"

        },
        fontWeight: "bold",
        fontSize: "40px",
        color: "white",
        lineHeight: "54px",
    },
    lineBreak: {
        width: "128px",
        height: "5px",
        background: "#0063F7",
        marginTop: "-4px"
    },
    active: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px !important"

        },
        fontWeight: "bold",
        color: "#0984e3 !important"
    },
    noActive: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px !important"

        },
        color: "black",
        fontWeight: "500"
    },
    tabLeft: {
        // border: "1px solid black",
        width: "50%",
        background: "#212b36",
        borderRadius: "0px 5px 0px 0px",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "500",
        paddingTop: "5px",
        paddingBottom: "5px",


    },
    tabRight: {
        // border: "1px solid black",
        width: "50%",
        background: "#212b36",
        borderRadius: "5px 0px 0px 0px",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "500",
        paddingTop: "5px",
        paddingBottom: "5px",

    },
    tabActiveRight: {
        width: "50%",
        background: "#212b36",
        // border: " 0.25px solid #C7C9D9",
        // boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.04)",
        borderRadius: "5px 0px 0px 0px",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "5px",
        paddingBottom: "5px",

    },
    tabActiveLeft: {
        width: "50%",
        background: "#212b36",
        // border: " 0.25px solid #C7C9D9",
        // boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.04)",
        borderRadius: "0px 5px 0px 0px",
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "5px",
        paddingBottom: "5px",

    },
    wrapper: {
        display: "inline-block",
        marginBottom: "-6px",
        // marginLeft: "1.25%",
        // marginRight: "1.25%",
        position: "relative",
        width: "100%",
        textAlign: "center",
    },
    centerItems: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    tabNavi:{
        textAlign: "center",
        marginBottom: "30px"
    },
    tabPane:{
        [theme.breakpoints.down("sm")]: {
            marginLeft: "4px !important"
        },
        position:"relative",
        marginTop:"-23px",
        width:"100%",
        backgroundColor: '#212b36',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: "10px",
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px'
        // boxShadow:"0px 3px 8px rgba(96, 97, 112, 0.32), 0px 5px 2px rgba(40, 41, 61, 0.04)"
    },
    tabPane2:{
        [theme.breakpoints.down("sm")]: {
            marginLeft: "4px !important",
            padding: "10px !important"
        },
        position:"relative",
        marginTop:"-23px",
        backgroundColor: "#212b36",
        padding: "20px",
        width:"100%",
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px'
        // boxShadow:"0px 3px 8px rgba(96, 97, 112, 0.32), 0px 5px 2px rgba(40, 41, 61, 0.04)"
    },
    container:{
        paddingBottom: "100px",
        paddingTop: "100px"
    },
    chart:{
        [theme.breakpoints.down("xs")]: {
            width: "150% !important",
            marginLeft: "-160px",
         },
        width: "100%",
    },
    noPaddingCol:{
        paddingLeft: "0px !important",
        paddingRight: "0px !important"
    }
})

export default overviewStyle;
