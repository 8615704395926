const initialState = { data: '', data2: ''}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BALLOT_DETAIL":
      return {
        ...state
      }
    case "GET_BALLOT_DETAIL_SUCCESS":
      return {
        ...state,
        data: action.data,
        data2: action.data2 ? action.data2 : ''
      }
    case "GET_BALLOT_DETAIL_FAILURE":
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
export default reducer
