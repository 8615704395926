const initialState = { series: [], categories: []}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CHART_DATA":
      return {
        ...state
      }
    case "GET_CHART_DATA_SUCCESS":
      return {
        ...state,
        series: action.series,
        categories: action.categories
      }
    case "GET_CHART_DATA_FAILURE":
      return {
        ...state,
      }
    default:
      return state
  }
}
export default reducer
