export const SAVE_USER_INFO = "SAVE_USER_INFO"
export const SAVE_USER_INFO_SUCCESS = "SAVE_USER_INFO_SUCCESS"
export const SAVE_USER_INFO_FAILURE = "SAVE_USER_INFO_FAILURE"

//get list contest create by account have (token)
export const GET_VOTE_LIST = "GET_VOTE_LIST"
export const GET_VOTE_LIST_SUCCESS = "GET_VOTE_LIST_SUCCESS"
export const GET_VOTE_LIST_FAILURE = "GET_VOTE_LIST_FAILURE"

//get ballot detail
export const GET_BALLOT_DETAIL = "GET_BALLOT_DETAIL"
export const GET_BALLOT_DETAIL_SUCCESS = "GET_BALLOT_DETAIL_SUCCESS"
export const GET_BALLOT_DETAIL_FAILURE = "GET_BALLOT_DETAIL_FAILURE"

//get ballot upComming
export const GET_BALLOT_UPCOMMING = "GET_BALLOT_UPCOMMING"
export const GET_BALLOT_UPCOMMING_SUCCESS = "GET_BALLOT_UPCOMMING_SUCCESS"
export const GET_BALLOT_UPCOMMING_FAILURE = "GET_BALLOT_UPCOMMING_FAILURE"

//get point all candidate
export const GET_POINT_ALL = "GET_POINT_ALL"
export const GET_POINT_ALL_SUCCESS = "GET_POINT_ALL_SUCCESS"
export const GET_POINT_ALL_FAILURE = "GET_POINT_ALL_FAILURE"

//get winner info
export const GET_INFO_WINNER = "GET_INFO_WINNER"
export const GET_INFO_WINNER_SUCCESS = "GET_INFO_WINNER_SUCCESS"
export const GET_INFO_WINNER_FAILURE = "GET_INFO_WINNER_FAILURE"

//get list vote
export const GET_LIST_VOTE = "GET_LIST_VOTE"
export const GET_LIST_VOTE_SUCCESS = "GET_LIST_VOTE_SUCCESS"
export const GET_LIST_VOTE_FAILURE = "GET_LIST_VOTE_FAILURE"

//save path website
export const SAVE_PATH_WEBSITE = "SAVE_PATH_WEBSITE"
export const SAVE_PATH_WEBSITE_SUCCESS = "SAVE_PATH_WEBSITE_SUCCESS"

//save array draw
export const SAVE_DRAW_LIST = 'SAVE_DRAW_LIST'
export const SAVE_DRAW_LIST_SUCCESS = 'SAVE_DRAW_LIST_SUCCESS'
export const SAVE_DRAW_LIST_FAILURE = 'SAVE_DRAW_LIST_FAILURE'

//save bka check in 
export const SAVE_BKA = 'SAVE_BKA'
export const SAVE_BKA_SUCCESS = 'SAVE_BKA_SUCCESS'
export const SAVE_BKA_FAILURE = 'SAVE_BKA_FAILURE'

//save list winner
export const SAVE_LIST_WINNER = 'SAVE_LIST_WINNER'
export const SAVE_LIST_WINNER_SUCCESS = 'SAVE_LIST_WINNER_SUCCESS'
export const SAVE_LIST_WINNER_FAILURE = 'SAVE_LIST_WINNER_FAILURE'

//save List Checkined
export const SAVE_LIST_CHECKIN = 'SAVE_LIST_CHECKIN'
export const SAVE_LIST_CHECKIN_SUCCESS = 'SAVE_LIST_CHECKIN_SUCCESS'
export const SAVE_LIST_CHECKIN_FAILURE = 'SAVE_LIST_CHECKIN_FAILURE'




