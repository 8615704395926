import React from "react";
import "./index.css";
import Loading from "./loading";
import Header from "./header"
import {Container, Row} from "reactstrap"
import Form from "./form"
import {connect} from "react-redux"
class Yearend extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true
        }
    }
  componentDidMount() {
      this.props.cleanDataUser();
    document.body.classList.add("bg-default");
    let timer = setTimeout(()=>{
        this.setState({
            loading: false
        });
        clearTimeout(timer)
    }, 2000)
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  render() {
    return (
      <>
       {this.state.loading ?
        <Loading/> : <div className="main-content">
        <Header />
        <div className="header bg-year-end py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <Container className="mt-form-submit pb-5">
          <Row className="justify-content-center px-3">
           <Form/>
          </Row>
        </Container>
      </div>}
      </>
    );
  }
}
const mapStateToProps = (state) => {
    return {
      userInfo: state.userInfo,
      pathWebsite: state.pathWebsite.data
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      cleanDataUser: () => dispatch({ type: "CLEAN_STORE"})
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(Yearend);
