import withStyles from '@material-ui/core/styles/withStyles'
import Axios from 'axios'

import { LINK_API, LOADING } from 'constants/API'
import moment from 'moment'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// reactstrap components
import { Button, Col, Container, Modal, Row, Card, CardFooter } from 'reactstrap'
import "./member.css"
import dashboardStyle from './memberMiniStyle.js'
import { withTranslation } from 'react-i18next';
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

class Result extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      nameModal: '',
      descriptionModal: '',
      nodeHeight: '',
      data: [],
      
      nameContest: "",
      timeContest: "",
      txContest: "",
      numberContest: "",
      avatarContest: ""
    }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handlePreVote = this.handlePreVote.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.handleVote = this.handleVote.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.hideAlertVote = this.hideAlertVote.bind(this)
    this.handleGetInfoContest = this.handleGetInfoContest.bind(this)
    this.handleSignIn = this.handleSignIn.bind(this)
  }

  componentDidMount() {
    if(this.props.ballotDetail){
      if(this.props.ballotDetail.data){
        this.handleGetInfoContest(this.props.ballotDetail.data)
        if(this.props.ballotDetail.data.candidates){
        }
      }
    }
    if(this.props.pointAll){
      if(this.props.pointAll.data){
        this.setState({
          data: this.props.pointAll.data
        })
    }
    }
  }
  componentDidUpdate(nextProps){
    if(this.props.ballotDetail !== nextProps.ballotDetail){
      if(this.props.ballotDetail.data){
        this.handleGetInfoContest(this.props.ballotDetail.data)
      }
    }
    if(this.props.pointAll !== nextProps.pointAll){
      if(this.props.pointAll.data){
        this.setState({
          data: this.props.pointAll.data
        })
      }
    }
  }
  handleSignIn(){
    if(this.props.match.path === "/voting/:id"){
      this.props.savePathWebsite(this.props.match.url)
    }
    this.props.history.push("/auth/register")
  }
  handleGetInfoContest(data){
    this.setState({
      nameContest: data.name,
      numberContest: data.candidates.length,
      txContest: data.tx_id,
      timeContest: "Từ " + moment(data.startTime).format("DD/MM/YYYY hh:mm") + " tới " + moment(data.endTime).format("DD/MM/YYYY hh:mm"),
      avatarContest: data.avatar ? data.avatar.url : "",
      network: data.network
    })
  }
  hideAlertVote(){
    this.setState({
      alert: null
    })
  }
  handleClose() {
    this.setState({
      openModal: false
    })
  }

  handleOpen(name, des, id) {
    this.setState({
      openModal: true,
      nameVote: name,
      descriptionVote: des,
      idVote: id
    })
  }
  handleCheck(name, id){
    this.setState({
      idVote: id,
      nameVote: name,
      openModal: false
    })
    if(this.props.userInfo.data){
      if(this.props.userInfo.data.name){
        this.handlePreVote(name, id)
      }else{
        this.setState({
          alert:(
            <SweetAlert
            warning
            style={{ display: "block" }}
            title=""
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
            showCancel={false}
          >
           <p className={this.props.classes.sweetTitle}>Vui lòng đăng nhập để tiếp tục!</p> 
             <Button color="danger" onClick={this.hideAlert}>{this.props.t("cancle")}</Button>
           <Button color="primary" onClick={()=> this.props.history.push("register-page")}>Đăng nhập</Button>
          </SweetAlert>
          )
        })
      }
    }else{
      this.setState({
        alert:(
          <SweetAlert
          warning
          style={{ display: "block" }}
          title="Vui lòng đăng nhập để tiếp tục!"
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          showCancel={false}
        >
           <Button color="danger" onClick={this.hideAlert}>{this.props.t("cancle")}</Button>
         <Button color="primary" style={{marginLeft: "10px"}} onClick={this.handleSignIn}>{this.props.t('login.login')}</Button>
        </SweetAlert>
        )
      })
    }
  }
  handleVote(id){
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={<span className='text-white'>{this.props.t("processing")}</span>}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <img alt="loading" src={LOADING}/>
        </SweetAlert>
      )
    })
    const data = {
      "vote_id": this.props.ballotDetail.data.vote_id,
     // "ballot_id": this.props.ballotDetail.data.ballot_id,
      "candidates": []
    }
    data.candidates.push(id || this.state.idVote)
    Axios.defaults.headers.common.Authorization = this.props.userInfo.data.token
    Axios.post(LINK_API.GET_INFO_VOTE, data)
    .then(res => {
      if(res.data.responseCode === 1){
        var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.pass);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2,privateKey.length);
        const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(res.data.data.rawTx, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        const data_raw = {
         ...res.data.data,
         rawTx: rawTx
        }
        Axios.post(LINK_API.VOTE, data_raw)
        .then(result => {
          if(result.data.responseCode === 1){
            this.setState({
              alert: (
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title={this.props.t("success")}
                  onConfirm={this.hideAlertVote}
                  onCancel={this.hideAlert}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  showConfirm={false}
                >
                  <Button color="success" onClick={this.hideAlertVote}>{this.props.t("confirm")}</Button>
                </SweetAlert>
              )
            })
          }else{
            this.setState({
              alert: (
                <SweetAlert
                  danger
                  style={{ display: "block" }}
                  title={this.props.t("failure")}
                  onConfirm={this.hideAlert}
                  onCancel={this.hideAlert}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  showConfirm={false}
                >
                  <Button color="primary" onClick={this.hideAlert}>{this.props.t("confirm")}</Button>
                </SweetAlert>
              )
            })
          }
        })
        .catch(err=> {
          console.log(err)
          this.setState({
            alert: (
              <SweetAlert
                danger
                style={{ display: "block" }}
                title={this.props.t("failure")}
                onConfirm={this.hideAlert}
                onCancel={this.hideAlert}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                showConfirm={false}
              >
                <Button color="primary" onClick={this.hideAlert}>{this.props.t("confirm")}</Button>
              </SweetAlert>
            )
          })
        })
      }else{
        this.setState({
          alert: (
            <SweetAlert
              danger
              style={{ display: "block" }}
              title={this.props.t("failure")}
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              showConfirm={false}
            >
              <Button color="primary" onClick={this.hideAlert}>{this.props.t("confirm")}</Button>
            </SweetAlert>
          )
        })
      }
    })
    .catch(err=> {
      console.log(err);
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block" }}
            title={this.props.t("failure")}
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
          >
             <Button color="primary" onClick={this.hideAlert}>{this.props.t("confirm")}</Button>
          </SweetAlert>
        )
      })
    })
  }
  handlePreVote(name, id){
    if(this.state.openModal){
      this.handleClose();
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Bình chọn cho"
          onConfirm={()=> this.handleVote(id)}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.confirm 
          }
          cancelBtnCssClass={
            this.props.classes.cancle
          }
          showConfirm={false}
          showCancel={false}
          confirmBtnText={this.props.t("confirm")}
          cancelBtnText={this.props.t("cancle")}
        >
          <p className={this.props.classes.votefor}>{name || this.state.nameVote}</p>
          <p className={this.props.classes.hightLight}>{this.props.t("vote.id")}:</p>
          <p className={this.props.classes.hightLight} style={{marginBottom: "20px"}}>{id || this.state.idVote}</p>
          <Button color="danger" onClick={this.hideAlert}>{this.props.t("cancle")}</Button>
          <Button style={{marginLeft: "10px"}} color="primary" onClick={()=>this.handleVote(id || this.state.idVote)}> {this.props.t("confirm")}</Button>
        </SweetAlert>
      )
    })
  }
  hideAlert(){
    this.setState({
      alert: null
    })
  }
  render() {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        <Container className={classes.noPadding}>
          <Row>
            {this.state.data.map((member, index) => (
              <Col key={index} xs={12} md={4} className={classes.paddingCol}>
                <Card >
                <div style={{display: "flex", flexFlow: "row"}}>
                  <Col xs={4} className={classes.paddingImage}>
                      <img alt="logo" src={member.image.length > 0 ? member.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1619502978/images/Asset_3_2x_xyyfup.png"} className='img-ava-mini'/>
                  </Col>
                  <Col xs={8}>
                    <p className="memberName">{member.name}</p>
                    <div className={classes.voteInfo}>
                      {/* <p className={classes.hightLight}> {t("vote.bcAddress")}: </p> */}
                    <p className={classes.idcandi}>{member.bc_address}</p>
                    </div>
                    <div className={classes.pointInfo}>
                      <p className={classes.hightLight}> {t("vote.votes")}: </p>
                    <p className={classes.point}>{member.point}</p>
                    </div>
                  </Col>
                </div>
                  <CardFooter chart style={{ textAlign: 'end', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='bg-transparent shadow-card-dark'>
                    <div className={classes.footerButton}>
                      <Button className='btn-gradient-info' size='sm' onClick={() => this.handleCheck(member.name, member.candidate_id, )} color="primary"> {t("vote.vote")}</Button>
                      {/* <Button size='sm' onClick={() => this.handleOpen(member.name, member.description, member.candidate_id)} > {t("vote.more")}</Button> */}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <Modal isOpen={this.state.openModal} toggle={this.handleClose}>
          <div className='modal-header'>
            <p
              className={classes.modaltitle}
              id='exampleModalLabel'
            >
              {this.state.nameVote}
            </p>
          </div>
          <div className='modal-body'>
            <p className={classes.modalBody}>{this.state.descriptionVote}</p>
          </div>
          <div className='modal-footer'>
            <div className='left-side'>
              <Button
                color='primary'
                type='button'
                onClick={() => this.handleCheck(this.state.nameVote, this.state.idVote)}
              >
                {t("vote.vote")}
              </Button>
            </div>
            <div className='divider' />
            <div className='right-side'>
              <Button  color='danger' type='button' onClick={this.handleClose}>
                {t("cancle")}
              </Button>
            </div>
          </div>
        </Modal>
        {this.state.alert}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    ballotDetail: state.ballotInfo,
    userInfo: state.userInfo,
    pathWebsite: state.pathWebsite.data,
    pointAll: state.pointAll
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getBallotDetail: (id) => dispatch({ type: "GET_BALLOT_DETAIL", id: id }),
    getPointAll: (id) => dispatch({ type: "GET_POINT_ALL", id: id }),
    getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id }),
    getInfoWinner: (id) => dispatch({ type: "GET_INFO_WINNER", id: id }),
    savePathWebsite: (data) => dispatch({ type: "SAVE_PATH_WEBSITE", data: data }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(withTranslation('common')(Result))));
