import withStyles from '@material-ui/core/styles/withStyles';
import Axios from 'axios';
import { LINK_API, LOADING } from 'constants/API';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Container, Modal, Row } from "reactstrap";
import ContentLoader from "./contentLoader";
import "./member.css";
import MemberMini from "./memberMini";
import dashboardStyle from './memberStyle';
import Overview from "./overview.js";
import QRCode from "react-qr-code";
import CountDown from "components/CountDown/index"
import AnimatedNumber from "react-animated-numbers"
import UserSVG from "assets/img/user.svg"
import QRSVG from "assets/img/qr-code.svg"
import MedalSVG from "assets/img/medal.svg"
import SuccessImg from "assets/img/success.gif"
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

class Result extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      nameModal: '',
      descriptionModal: '',
      nodeHeight: '',
      data: [],

      nameContest: "",
      timeContest: "",
      txContest: "",
      numberContest: "",
      avatarContest: "",
      listType: this.props.t('vote.detail'),

      allVote: 0
    }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handlePreVote = this.handlePreVote.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.handleVote = this.handleVote.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.hideAlertVote = this.hideAlertVote.bind(this)
    this.handleGetInfoContest = this.handleGetInfoContest.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleSavePath = this.handleSavePath.bind(this)
  }

  componentDidMount() {

    if (this.props.ballotDetail) {
      if (this.props.ballotDetail.data) {
        this.handleGetInfoContest(this.props.ballotDetail.data)
        if (this.props.ballotDetail.data.candidates) {
        }
      }
    }
    if (this.props.match) {
      if (this.props.match.params.id) {
        this.props.getBallotDetail(this.props.match.params.id)
        this.props.getPointAll(this.props.match.params.id)
        this.props.getListVote(this.props.match.params.id)
      }
    }
    if (this.props.pointAll) {
      if (this.props.pointAll.data) {
        this.setState({
          data: this.props.pointAll.data,
        })
      }
    }
    if (this.props.listVote) {
      if (this.props.listVote.data) {
        this.setState({
          allVote: this.props.listVote.data.length
        })
      }
    }
    this.props.savePathWebsite("")
  }
  componentDidUpdate(nextProps) {
    if (this.props.ballotDetail !== nextProps.ballotDetail) {
      if (this.props.ballotDetail.data) {
        this.handleGetInfoContest(this.props.ballotDetail.data)
      }
    }
    if (this.props.pointAll !== nextProps.pointAll) {
      if (this.props.pointAll.data) {
        this.setState({
          data: this.props.pointAll.data,
        })
      }
    }
  }

  handleSavePath() {
    this.props.savePathWebsite(this.props.match.url)
    this.props.history.push("/auth/register")
  }

  handleGetInfoContest(data) {
    this.setState({
      nameContest: data.name,
      numberContest: data.candidates.length,
      txContest: data.vote_id,
      timeContest: "Từ " + moment(data.startTime).format("DD/MM/YYYY hh:mm") + " tới " + moment(data.endTime).format("DD/MM/YYYY hh:mm"),
      avatarContest: data.images.length > 0 ? (data.images[0] ? data.images[0].url : "") : "",
      network: data.network
    })
  }

  hideAlertVote() {
    this.setState({
      alert: null
    })
  }

  handleClose() {
    this.setState({
      openModal: false
    })
  }

  handleChangeView(data) {
    this.setState({
      listType: data
    })
  }

  handleOpen(name, des, id) {
    this.setState({
      openModal: true,
      nameVote: name,
      descriptionVote: des,
      idVote: id
    })
  }
  handleCheck(name, id) {
    this.setState({
      idVote: id,
      nameVote: name,
      openModal: false
    })
    if (this.props.userInfo.data) {
      if (this.props.userInfo.data.name) {
        this.handlePreVote(name, id)
      } else {
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title=""
              customClass='bg-dark-popup'
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              showConfirm={false}
              showCancel={false}
            >
              <p className={this.props.classes.sweetTitle}>{this.props.t('vote.login')}</p>
              <Button color="danger" onClick={this.hideAlert}>{this.props.t('cancle')}</Button>
              <Button color="primary" className='btn-gradient-info' onClick={this.handleSavePath}>{this.props.t('login.login')}</Button>
            </SweetAlert>
          )
        })
      }
    } else {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title={<span className='text-white'>{this.props.t('vote.login')}</span>}
            customClass='bg-dark-popup'
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
            showCancel={false}
          >
            <Button color="danger" onClick={this.hideAlert}>{this.props.t('cancle')}</Button>
            <Button color="primary" className='btn-gradient-info' style={{ marginLeft: "10px" }} onClick={() => this.props.history.push("/auth/register")}>{this.props.t('login.login')}</Button>
          </SweetAlert>
        )
      })
    }
  }
  handleVote(id) {
    this.setState({
      alert: (
        <SweetAlert
          customClass='bg-dark-popup'
          warning
          style={{ display: "block" }}
          title={<span className='text-white'>{this.props.t("processing")}</span>}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <img alt="loading" src={LOADING} />
        </SweetAlert>
      )
    })
    const data = {
      "vote_id": this.props.ballotDetail.data.vote_id,
      "candidates": []
    }
    data.candidates.push(id || this.state.idVote)
    Axios.defaults.headers.common.Authorization = this.props.userInfo.data.token
    Axios.post(LINK_API.GET_INFO_VOTE, data)
      .then(res => {
        if (res.data.responseCode === 1) {
          var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.pass);
          const privateKey = bytes.toString(CryptoJS.enc.Utf8);
          const privatekey_slice = privateKey.substring(2, privateKey.length);
          const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
          const tx = new EthereumTx(res.data.data.rawTx, { chain: 4 });
          tx.sign(Buffer_privatekey);
          const rawTx = '0x' + tx.serialize().toString('hex');
          const data_raw = {
            ...res.data.data,
            rawTx: rawTx
          }
          Axios.post(LINK_API.VOTE, data_raw)
            .then(result => {
              if (result.data.responseCode === 1) {
                this.setState({
                  alert: (
                    <SweetAlert
                      // customIcon={CheckSVG}
                      customClass='bg-dark-popup'
                      style={{ display: "block" }}
                      title={""}
                      onConfirm={this.hideAlertVote}
                      onCancel={this.hideAlert}
                      confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                      }
                      showConfirm={false}
                    >
                      <div className='d-flex flex-column justify-content-center align-items-center'>
                      <img src={SuccessImg} alt='success' style={{height: '200px', width: '200px'}}/>
                      <span className='text-primary success-title'>{this.props.t("success")}</span>

                      <Button color="primary" className='btn-gradient-info' onClick={this.hideAlertVote}>{this.props.t('confirm')}</Button>
                      </div>
                    </SweetAlert>
                  )
                })
              } else {
                this.setState({
                  alert: (
                    <SweetAlert
                      danger
                      style={{ display: "block" }}
                      customClass='bg-dark-popup'
                      title={<span className='text-danger'>{this.props.t("failure")}</span>}
                      onConfirm={this.hideAlert}
                      onCancel={this.hideAlert}
                      confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                      }
                      showConfirm={false}
                    >
                      <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
                    </SweetAlert>
                  )
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.setState({
                alert: (
                  <SweetAlert
                    danger
                    customClass='bg-dark-popup'
                    style={{ display: "block" }}
                    title={<span className='text-danger'>{this.props.t("failure")}</span>}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                      this.props.classes.button + " " + this.props.classes.success
                    }
                    showConfirm={false}
                  >
                    <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
                  </SweetAlert>
                )
              })
            })
        } else {
          this.setState({
            alert: (
              <SweetAlert
                danger
                customClass='bg-dark-popup'
                style={{ display: "block" }}
                title={<span className='text-danger'>{this.props.t("failure")}</span>}
                onConfirm={this.hideAlert}
                onCancel={this.hideAlert}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                showConfirm={false}
              >
                <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
              </SweetAlert>
            )
          })
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          alert: (
            <SweetAlert
              danger
              customClass='bg-dark-popup'
              style={{ display: "block" }}
              title={<span className='text-danger'>{this.props.t("failure")}</span>}
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              showConfirm={false}
            >
              <Button color="primary" className='btn-gradient-info' onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
            </SweetAlert>
          )
        })
      })
  }
  handlePreVote(name, id) {
    if (this.state.openModal) {
      this.handleClose();
    }
    this.setState({
      alert: (
        <SweetAlert
          warning
          customClass='bg-dark-popup'
          title={<span className='text-white'>Bình chọn cho</span>}
          onConfirm={() => this.handleVote(id)}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.confirm
          }
          cancelBtnCssClass={
            this.props.classes.cancle
          }
          showConfirm={false}
          showCancel={false}
          confirmBtnText={this.props.t('confirm')}
          cancelBtnText={this.props.t('cancle')}
        >
          <p className={this.props.classes.votefor}>{name || this.state.nameVote}</p>
          {/* <p className={this.props.classes.hightLight}>Mã số: </p> */}
          <p className={this.props.classes.hightLight} style={{ marginBottom: "20px" }}>{id || this.state.idVote}</p>
          <Button color="danger" onClick={this.hideAlert}>{this.props.t('cancle')}</Button>
          <Button style={{ marginLeft: "10px" }} color="primary" className='btn-gradient-info' onClick={() => this.handleVote(id || this.state.idVote)}>{this.props.t('confirm')}</Button>
        </SweetAlert>
      )
    })
  }
  hideAlert() {
    this.setState({
      alert: null
    })
  }
  render() {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        <Container>
          <Row className={classes.paddingInfo}>
            <Card style={{ width: "100%" }} className='shadow-card-dark'>
              <Row>
                <Col xs={12} md={6} className='d-flex justify-content-center align-items-center '>
                  <div className={classes.avatar}
                    style={{ backgroundImage: `url(${this.state.avatarContest})` }}>
                  </div>
                  {/* <QRCode value={"https://dash.smartballot.io" + this.props.history.location.pathname}/> */}
                </Col>
                <Col xs={12} md={6} className={classes.paddingCard}>
                  <p className={classes.contestName}>{this.state.nameContest}</p>
                  <div className='d-flex'>
                    <div className='d-flex w-50'>
                      <div className='icon-overview'>
                        <img src={UserSVG} alt='profile' className='profile-icon' />
                      </div>
                      <div className='pl-2 text-title-overview'>
                        <span>Số lượng ứng cử viên</span>
                        <div className='d-flex align-items-center'>
                          <AnimatedNumber
                            fontStyle={{ fontFamily: "Muli", fontSize: 17, color: "#d2d2d2", fontWeight: 'bold' }}
                            animateToNumber={this.state.numberContest}
                            includeComma
                            config={{ tension: 89, friction: 40 }}
                            onStart={() => console.log("onStart")}
                            onFinish={() => console.log("onFinish")}
                            animationType={"calm"}
                          />
                          <p className='ml-2 mb-0'>ứng cử viên</p>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex w-50'>
                      <div className='icon-overview'>
                        <img src={MedalSVG} alt='profile' className='profile-icon' />
                      </div>
                      <div className='pl-2 text-title-overview'>
                        <span>Số lượt bình chọn</span>
                        <div className='d-flex align-items-center'>
                          <AnimatedNumber
                            fontStyle={{ fontFamily: "Muli", fontSize: 17, color: "#d2d2d2", fontWeight: 'bold' }}
                            animateToNumber={this.state.allVote}
                            includeComma
                            // config={{ tension: 200, friction: 40 }}
                            onStart={() => console.log("onStart")}
                            onFinish={() => console.log("onFinish")}
                            animationType={"random"}
                          />
                          <p className='ml-2 mb-0'>lượt bình chọn</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className='d-flex w-100'>
                      <div className='icon-overview'>
                        <img src={QRSVG} alt='profile' className='profile-icon' />
                      </div>
                      <div className='pl-2 text-title-overview'>
                        <span>Mã cuộc thi</span>
                        <div className='d-flex align-items-center'>
                          <p className='text-number-overview-id'>{this.state.txContest}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className={classes.contestTitle}>{t('vote.time')}:</p>
                    <div className='mt-3 pb-4'>
                      <CountDown />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>
          <Overview />
          <div className='title' style={{ textAlign: "center" }}>
            <p className={classes.subTitle}>DANH SÁCH</p>
            <hr className={classes.lineBreak} />
            <p className={classes.listTitle}>ỨNG CỬ VIÊN</p>
          </div>
          <Row style={{ textAlign: "right" }}>
            <Col xs={12}>
              <ButtonGroup>
                <Button color="primary" className='btn-gradient-info' onClick={() => this.handleChangeView(t('vote.detail'))} active={this.state.listType === t('vote.detail')}>{t('vote.detail')}</Button>
                <Button color="primary" className='btn-gradient-info' onClick={() => this.handleChangeView(t('vote.collapse'))} active={this.state.listType === t('vote.collapse')}>{t('vote.collapse')}</Button>
              </ButtonGroup>
            </Col>
          </Row>
          {this.state.data.length === 0 ?
            <Row style={{ marginTop: "10px" }}>
              <Col xs={12} md={4}>
                <Card >
                  <CardBody>
                    <ContentLoader />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card >
                  <CardBody>
                    <ContentLoader />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card >
                  <CardBody>
                    <ContentLoader />
                  </CardBody>
                </Card>
              </Col>
            </Row> : null}
          {this.state.listType === t('vote.detail') ?
            <Row>
              {this.state.data.map((member, index) => (
                <Col key={index} xs={12} md={4} className={classes.paddingCol}>
                  <Card style={{ height: "600px", }}>
                    <div style={{
                      backgroundImage: `url(${member.image.length > 0 ? member.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1619502978/images/Asset_3_2x_xyyfup.png"})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "bottom",
                      height: "350px", width: "100%", display: "block", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", boxShadow: "0px 16px 24px rgba(96, 97, 112, 0.16), 0px 2px 8px rgba(40, 41, 61, 0.04)"
                    }}>
                    </div>
                    <div className={classes.votePie}>
                      <p className={classes.voteNumber}>{member.point}</p>
                      <p className={classes.voteLabel}>lượt</p>
                    </div>
                    <CardBody>
                      <p className="memberName">{member.name}</p>
                      <div style={{ height: "50px" }}>
                        <p className="memberDescription">
                          {member.description}
                        </p>
                      </div>
                      <div className={classes.voteInfo}>
                        {/* <p className={classes.hightLight}>{t('vote.bcAddress')}: </p> */}
                        <p className={classes.idcandi}>{member.bc_address}</p>
                      </div>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'end', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='bg-transparent shadow-card-dark'>
                      <div >
                        <Button size='md' color="primary" className='btn-gradient-info' onClick={() => this.handleCheck(member.name, member.candidate_id,)}>{t('vote.vote')}</Button>
                        {/* <Button size='sm' onClick={() => this.handleOpen(member.name, member.description, member.candidate_id)}>{t('vote.more')}</Button> */}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              ))}

            </Row> : <MemberMini />}
        </Container>
        <Modal isOpen={this.state.openModal} toggle={this.handleClose}>
          <div className='modal-header'>

            <p
              className={classes.modaltitle}
              id='exampleModalLabel'
            >
              {this.state.nameVote}
            </p>
          </div>
          <div className='modal-body'>
            <p className={classes.modalBody}>{this.state.descriptionVote}</p>
          </div>
          <div className='modal-footer'>
            <div className='left-side'>
              <Button
                color='primary'
                type='button'
                onClick={() => this.handleCheck(this.state.nameVote, this.state.idVote)}
              >
                Bình chọn
              </Button>
            </div>
            <div className='divider' />
            <div className='right-side'>
              <Button color='danger' type='button' onClick={this.handleClose}>
                {this.props.t('cancle')}
              </Button>
            </div>
          </div>
        </Modal>
        {this.state.alert}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    ballotDetail: state.ballotInfo,
    userInfo: state.userInfo,
    pathWebsite: state.pathWebsite.data,
    pointAll: state.pointAll,
    listVote: state.listVote,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getBallotDetail: (id) => dispatch({ type: "GET_BALLOT_DETAIL", id: id }),
    getPointAll: (id) => dispatch({ type: "GET_POINT_ALL", id: id }),
    getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id }),
    getInfoWinner: (id) => dispatch({ type: "GET_INFO_WINNER", id: id }),
    savePathWebsite: (data) => dispatch({ type: "SAVE_PATH_WEBSITE", data: data }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(withTranslation('common')(Result))));
