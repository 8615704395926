/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

  
  const dashboardStyle = theme => ({
      footerButton:{
      display: "flex",
      flexFlow: "row",
      textAlign: "center"
    },
    confirm:{
      backgroudColor: "#0063F7 !important",
      background: "#0063F7 !important",
      color: "white !important",
      boxShadow: "none !important",
      border: "none !important"
    },
    voteButton:{
      '&:hover': {
        background: "white",
        color: "#0063F7",
        border: "2px solid #0063F7",
        borderColor: "#0063F7 !important"
      },
      '&:focus': {
        background: "white !important",
        backgroundColor: "white !important",
        color: "#0063F7 !important",
        border: "2px solid #0063F7"
      },
      background: "linear-gradient(169.7deg, #5B8DEF 6.95%, #0063F7 93.05%)",
      backgroudColor: "#0063F7",
      borderRadius: "6px",
     // boxShadow: "0px 8px 16px rgba(96, 97, 112, 0.16), 0px 2px 4px rgba(40, 41, 61, 0.04)",
      border: "2px solid #5B8DEF",
     // borderColor: "#fff !important",
      boxShadow: "none !important"
    },
    cancle:{
      '&:hover': {
        backgroudColor: "#ee5253 !important",
        background: "#ee5253 !important",
        color: "white !important"
      },
      '&:focus': {
        backgroudColor: "#ee5253 !important",
        background: "#ee5253 !important",
        color: "white !important"
      },
      backgroudColor: "#ee5253 !important",
      background: "#ee5253 !important",
      color: "white !important"
    },
    lineBreak:{
      width: "128px",
      height: "5px",
      background: "#0063F7",
      marginTop: "-4px"
    },
    detailButton:{
      '&:hover': {
        border: '1px solid white',
        background: "linear-gradient(169.7deg, #5B8DEF 6.95%, #0063F7 93.05%) !important",
        color: "white"
      },
      background: "white",
      color: "#0063F7",
      borderRadius: "6px",
      border: "1px solid #0063F7",
      marginLeft:"5px"
    },
    subTitle:{
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "32px",
      color: "#004FC4"
    },
    listTitle:{
      [theme.breakpoints.down("sm")]: {
         fontSize: "30px !important"
        
      },
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "54px",
    },
    cardTitle: {
      marginTop: '0px',
      marginBottom: '3px'
    },
    cardIconTitle: {
      marginTop: '15px',
      marginBottom: '0px'
    },
    cardProductTitle: {
      marginTop: '0px',
      marginBottom: '3px',
      textAlign: 'center'
    },
    cardCategory: {
      fontSize: '14px',
      paddingTop: '10px',
      marginBottom: '0',
      marginTop: '0',
      margin: '0'
    },
    cardProductDesciprion: {
      textAlign: 'center',
    },
    stats: {
      fontSize: '12px',
      lineHeight: '22px',
      display: 'inline-flex',
      '& svg': {
        position: 'relative',
        top: '4px',
        width: '16px',
        height: '16px',
        marginRight: '3px'
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        position: 'relative',
        top: '4px',
        fontSize: '16px',
        marginRight: '3px'
      }
    },
    productStats: {
      paddingTop: '7px',
      paddingBottom: '7px',
      margin: '0'
    },
    upArrowCardCategory: {
      width: 14,
      height: 14
    },
    underChartIcons: {
      width: '17px',
      height: '17px'
    },
    price: {
      color: 'inherit',
      '& h4': {
        marginBottom: '0px',
        marginTop: '0px'
      }
    },
    container: {
      height: "100%",
     
    },
    voteInfo:{
      display: "flex",
      flexFlow: "column"
      // paddingLeft: "15px",
      // paddingRight: "15px"
    },
    idcandi:{
      color: "white",
      fontWeight: "500",
      textDecoration: "underline",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"

    },
    hightLight: {
      color: "#d2d2d2"
    },
    votePie:{
      height: "80px",
      width: "80px",
      background: "linear-gradient(10deg, #00d2ff 0%, #3a47d5 100%)",
      opacity: "1",
      borderRadius: "50%",
      position: "absolute",
      top: "20px",
      left: "20px",
      //border: "5px solid #4471fe",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "column"
    },
    voteNumber:{
      color: "white",
      fontWeight: "600 ",
      fontSize: "20px"
    },
    voteLabel:{
      color: "white",
      marginTop: "-25px",
    },
    votefor:{
      fontSize: "20px",
      fontWeight: "700",
      color: "#0063F7",
      marginBottom: "10px"
    },
    modalBody:{
      fontWeight: "500"
    },
    modaltitle:{
      fontWeight: "700"
    },
    sweetTitle:{
      fontWeight: "500"
    },
    paddingInfo:{
      padding: "15px"
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        height: "315px !important",
        borderBottomLeftRadius: "0px !important",
        borderTopRightRadius: "6px !important"
      },
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: "6px",
      height: "100%",
      padding:"20px"
    },
    paddingCard:{
      padding: "40px"
    },
    contestName:{
      fontWeight: "700",
      fontSize: "25px"
    },
    contestTitle:{
      fontWeight: "700"
      
    },
    contestDesc:{
      fontWeight: "500"
    },
    flexColumn:{
      display: "flex",
      flexFlow: "column"
    },
    paddingImage:{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: "0px 0px !important",
        width: '100px',
        borderRadius: '50%',
        overflow: 'hidden',
        backgroudColor: "black"
    },
    pointInfo:{
        display: "flex",
        flexFlow:"row"
    },
    point:{
        fontWeight: "500",
        marginLeft: "5px",
        color: 'white'
    },
    noPadding:{
      padding: "0px 0px !important"
    },
    paddingCol:{
      paddingTop: "12px",
      paddingBottom: "12px"
    }
  })
  
  export default dashboardStyle
  