/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LINK_API, LOADING } from "constants/API.js"
import axios from "axios"
import { connect } from 'react-redux';
import "./styles/register.css"
import LoginSVG from "assets/img/illu/illustration_500.svg"
import * as Yup from 'yup'
import { useFormik } from "formik"

const ethers = require('ethers');

function Register(...props) {
  const phone_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  const { saveInfo, history, userInfo } = props[0];
  const t = props[0].t;

  const [state, setState] = React.useState({
    isRegister: false,
    phone: "",
    name: "",
    email: "",
    passWord: "",
    alert: null,
    phoneValid: false,
    passWordValid: false,
    passWordInValid: false,
    phoneInValid: false,
    nameValid: false,
    nameInValid: false,
    emailValid: false,
    emailInValid: false
  })
  const changeToRegister = () => {
    setState({
      ...state,
      isRegister: !state.isRegister
    })
  }
  React.useEffect(() => {
    if (userInfo.data) {
      props[0].history.push('/admin')
    }
  }, [userInfo])

  const hideAlert = () => {
    setState({
      ...state,
      alert: null
    })
  }
  const hideAlertLoginSuccess = () => {
    setState({
      ...state,
      alert: null
    })
    if (props[0].pathWebsite) {
      history.push(props[0].pathWebsite)
    } else {
      history.push("/admin/list")
    }

  }
  const handleRegister = (values) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          warning
          customClass='bg-dark-popup'
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={hideAlert}
          onCancel={hideAlert}
          showConfirm={false}
        >
          <img alt="loading" src={LOADING} />
        </SweetAlert>
      )
    })
    const data = {
      "name": values.name,
      "company": "AGD",
      "email": values.email,
      "phone_number": values.phone,
      "password": values.password,
      "position": "",
      "address": "",
      "website": ""
    }
    axios.post(LINK_API.REGISTER, data)
      .then(res => {
        if (res.data.responseCode === 1) {
          setState({
            ...state,
            alert: (
              <SweetAlert
                success
                style={{ display: "block" }}
                title={t('success')}
                onConfirm={changeToRegister}
                onCancel={hideAlert}
                showConfirm={true}
              >
              </SweetAlert>
            )
          })
        } else {
          setState({
            ...state,
            alert: (
              <SweetAlert
                danger
                style={{ display: "block" }}
                title={t('failure')}
                onConfirm={hideAlert}
                onCancel={hideAlert}
                showConfirm={true}
              >
              </SweetAlert>
            )
          })
        }
      })
      .catch((error) => {
        const message = error.response.data.responseCode === "EMAIL_EXIST" ? "Địa chỉ email đã tồn tại" : error.response.data.responseCode === "PHONE_EXIST" ? "Số điện thoại đã tồn tại" : ""
        setState({
          ...state,
          alert: (
            <SweetAlert
              danger
              style={{ display: "block" }}
              title={t('failure')}
              onConfirm={hideAlert}
              onCancel={hideAlert}
              showConfirm={true}
            >
              {message}
            </SweetAlert>
          )
        })
      })
  }
  const handleSignin = async (values) => {
    setState({
      ...state,
      alert: (
        <SweetAlert
          warning
          customClass='bg-dark-popup'
          style={{ display: "block" }}
          title={<span className='text-white'>{t('processing')}</span>}
          onConfirm={hideAlert}
          onCancel={hideAlert}
          showConfirm={false}
        >
          <img alt="loading" src={LOADING} />
        </SweetAlert>
      )
    })

    var temp = new Buffer(values.password).toString('hex')
    const encryptedPass = await ethers.utils.keccak256('0x' + temp)

    const data = {
      "phone_number": values.phone,
      "password": encryptedPass
    }
    axios.post(LINK_API.LOGIN, data)
      .then(res => {
        if (res.data.responseCode === 1) {
          saveInfo(res.data.data, values.password)
          hideAlertLoginSuccess();
        } else {
          setState({
            ...state,
            alert: (
              <SweetAlert
                danger
                style={{ display: "block" }}
                title={t('failure')}
                onConfirm={hideAlert}
                onCancel={hideAlert}
                showConfirm={true}
              >
              </SweetAlert>
            )
          })
        }
      })
      .catch(err => {
        setState({
          ...state,
          alert: (
            <SweetAlert
              danger
              style={{ display: "block" }}
              title={t('failure')}
              onConfirm={hideAlert}
              onCancel={hideAlert}
              showConfirm={true}
            >
            </SweetAlert>
          )
        })
      })
  }

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().matches(phone_regex, t('phoneInvalid'))
        .required(t('require')),
      password: Yup.string()
        .min(5, t('atLeast'))
        .required(t('require')),
    }),
    onSubmit: async values => {
      handleSignin(values)
    },
  });
  const formikRegister = useFormik({
    initialValues: {
      phone: '',
      password: '',
      name: '',
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('invalidMail'))
        .required(t('require')),
      name: Yup.string()
        .required(t('require')),
      phone: Yup.string().matches(phone_regex, t('phoneInvalid'))
        .required(t('require')),
      password: Yup.string()
        .min(5, t('atLeast'))
        .required(t('require')),
    }),
    onSubmit: async values => {
      handleRegister(values)
    },
  });
  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-transparent shadow-none border-0">
          <CardHeader className="bg-transparent">
            <Row className="justify-content-center">
              <Col xs={12} className="text-center">
                <img src="https://res.cloudinary.com/agridential/image/upload/v1583380497/images/Smart_Ballot_2x_1_wutmia.png" style={{ height: '60px' }} alt="logo" />
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {!state.isRegister ?
              <Form role="form" className='form-login' onSubmit={formik.handleSubmit}>
                <FormGroup className={formik.touched.phone && formik.errors.phone ? "has-danger" : (formik.values.phone ? "has-success" : false)} >
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='text'
                      placeholder={t('login.phone')}
                      className={formik.touched.phone && formik.errors.phone ? "is-invalid" : (formik.values.phone ? "is-valid" : false)}
                      value={formik.values.phone}
                      name="phone"
                      autoComplete=''
                      {...formik.getFieldProps('phone')}
                    />

                  </InputGroup>
                </FormGroup>
                <FormGroup className={formik.touched.password && formik.errors.password ? "has-danger" : (formik.values.password ? "has-success" : false)}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className={formik.touched.password && formik.errors.password ? "is-invalid" : (formik.values.password ? "is-valid" : false)}
                      placeholder={t('login.pass')}
                      type={formik.values.showPass ? "text" : "password"}
                      name="password"
                      value={formik.values.password}
                      {...formik.getFieldProps('password')}
                    />

                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="mt-4 btn-block btn-gradient-info" color='primary' type="summit" >
                    {t('login.login')}
                  </Button>
                </div>
              </Form> :
              <Form role="form" className='form-login' onSubmit={formikRegister.handleSubmit}>
                <FormGroup className={formikRegister.touched.name && formikRegister.errors.name ? "has-danger" : (formikRegister.values.name ? "has-success" : false)}>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='text'
                      placeholder={t('login.name')}
                      className={formikRegister.touched.name && formikRegister.errors.name ? "is-invalid" : (formikRegister.values.name ? "is-valid" : false)}
                      value={formikRegister.values.name}
                      name="name"
                      autoComplete='name'
                      {...formikRegister.getFieldProps('name')}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className={formikRegister.touched.email && formik.errors.email ? "has-danger" : (formikRegister.values.email ? "has-success" : false)}>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='email'
                      placeholder={t('login.email')}
                      className={formikRegister.touched.email && formik.errors.email ? "is-invalid" : (formik.values.email ? "is-valid" : false)}
                      value={formikRegister.values.email}
                      name="email"
                      {...formikRegister.getFieldProps('email')}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className={formikRegister.touched.phone && formikRegister.errors.phone ? "has-danger" : (formikRegister.values.phone ? "has-success" : false)}>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='text'
                      placeholder={t('login.phone')}
                      className={formikRegister.touched.phone && formikRegister.errors.phone ? "is-invalid" : (formikRegister.values.phone ? "is-valid" : false)}
                      value={formikRegister.values.phone}
                      name="phone"
                      {...formikRegister.getFieldProps('phone')}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className={formikRegister.touched.password && formikRegister.errors.password ? "has-danger" : (formikRegister.values.password ? "has-success" : false)}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className={formikRegister.touched.password && formikRegister.errors.password ? "is-invalid" : (formikRegister.values.password ? "is-valid" : false)}
                      placeholder={t('login.pass')}
                      type={formikRegister.values.showPass ? "text" : "password"}
                      name="password"
                      value={formikRegister.values.password}
                      {...formikRegister.getFieldProps('password')}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4 btn-block" color="primary" type="submit">
                    {t('login.register')}
                  </Button>
                </div>
              </Form>
            }
            <div className="text-center">
              {!state.isRegister ? <p className="haveAccount">{t('login.noAccount')} <span className='loginText' onClick={changeToRegister}>{t('login.register')}</span></p> :
                <p className="haveAccount">{t('login.haveAccount')} <span className='loginText' onClick={changeToRegister}>{t('login.login')}</span></p>}
            </div>
          </CardBody>
        </Card>
        {state.alert}
      </Col>

      <Col lg="6" md="0" className='d-none d-lg-flex justify-content-end align-items-center'>
        <img src={LoginSVG} alt='img-login' style={{ height: '400px' }} />
      </Col>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    pathWebsite: state.pathWebsite.data
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord })
  }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Register));
