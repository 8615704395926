/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// core components
import MilkTeaFooter from "components/Footers/MilkTea";
import VoteNavbar from "components/Navbars/MIilkNavbar.js";
import React from "react";
import { connect } from 'react-redux';
import Member from "./member.js";

class Auth extends React.Component {
  componentDidMount() {
    if(this.props.match.path === "/milktea/:id"){
      this.props.savePathWebsite("/milktea/" + this.props.match.params.id)
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  render() {
    return (
      <>
        <div style={{background: "#161C23"}}>
          <VoteNavbar />
          <div style={{width:"100%", marginTop: "100px"}}>
          <Member/>
          </div>
        </div>
        <MilkTeaFooter />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ballotDetail: state.ballotDetail,
    userInfo: state.userInfo
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    savePathWebsite: (data) => dispatch({ type: "SAVE_PATH_WEBSITE", data: data }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
