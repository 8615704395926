import { SAVE_LIST_WINNER } from "actions";
import React from "react";
import Confetti from "react-dom-confetti";
import { connect } from "react-redux";
import TextLoop from "react-text-loop";
import { Card, Col, Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import "./index.css";

const Result = (props) => {
    const [array, setArray] = React.useState([]);

    const [allInfoCand, setAll] = React.useState([])
    const getListCand = props.getBallotDetail;
    const ballotDetail = props.ballotDetail;
    const saveDrawList = props.saveDrawList;
    const drawList = props.drawList;

    React.useEffect(() => {
        getListCand('0x7c16103f97d929328b126605141e130599db1214');
    }, [getListCand]);

    React.useEffect(() => {
        let temp = [];
        if (ballotDetail) {
            if (ballotDetail.candidates)
                if (ballotDetail.candidates.length < drawList.dataFull.length || drawList.data.length === 0) {
                    for (let i = 0; i < ballotDetail.candidates.length; i++) {
                        if (ballotDetail.candidates[i].name && ballotDetail.candidates[i].description &&  ballotDetail.candidates[i].status === 'A') {
                            temp.push(ballotDetail.candidates[i].name + " - " + ballotDetail.candidates[i].description);
                        } else {
                            if (ballotDetail.candidates[i].name &&  ballotDetail.candidates[i].status === 'A') {
                                temp.push(ballotDetail.candidates[i].name)
                            }
                        }
                    }
                    setArray(temp);
                    setAll(ballotDetail.candidates);
                    saveDrawList(temp, ballotDetail.candidates)
                    setStateShow(false)
                }
        }
    }, [ballotDetail, saveDrawList, drawList])

    const [drawState, setDraw] = React.useState(false);
    const listWinnerStore = props.listWinnerStore;

    React.useEffect(() => {
        console.log(listWinnerStore)
        if (listWinnerStore) {
            setWinner(listWinnerStore)
        }
    }, [listWinnerStore])
    const handleDraw = () => {
        setDraw(true);
        setStateShow(false);
        setConfetti(false);
        let timer = setTimeout(() => {
            //random data
            let maxItemIndex = array.length;
            const randomIndex = Math.floor(Math.random() * maxItemIndex);
            setResult(array[randomIndex]);
            let tempArray = listWinner;

            let tempStr = array[randomIndex].split('-');
            console.log(allInfoCand[randomIndex])
            tempArray.unshift({
                name: tempStr[0],
                course: tempStr[1],
                tx_id: allInfoCand[randomIndex].tx_id
            });
            console.log(array[randomIndex])
            props.saveListWinner(tempArray)
            // setWinner(tempArray)
            // handleVote(allInfoCand[randomIndex]);
            //setactive and unactive
            setDraw(false);
            setStateShow(true);
            setConfetti(true);
            clearTimeout(timer);
            //remove item drawed
            const copyCurrentItems = [...array];
            copyCurrentItems.splice(randomIndex, 1);
            //setArray(copyCurrentItems);
            //remove item list all info of candidate
            const copyCurrentItemsAll = [...allInfoCand];
            copyCurrentItemsAll.splice(randomIndex, 1);
            // setAll(copyCurrentItemsAll);
            saveDrawList(copyCurrentItems, copyCurrentItemsAll)
        }, 3000)
    }

    React.useEffect(() => {
        if (drawList) {
            if (drawList.data && drawList.dataFull) {
                if (drawList.data.length > 0) {
                    setArray(drawList.data);
                    setAll(drawList.dataFull);
                    if (array.length === 0) {
                        setStateShow(false);
                    }
                }
            }
        }
    }, [drawList, array])

    const [result, setResult] = React.useState('');
    const [showResult, setStateShow] = React.useState(true);
    const [confettiState, setConfetti] = React.useState(false);
    const [listWinner, setWinner] = React.useState([]);


   
    return (
        <>
            <div className='bg-yearend_v2'>
                <div className='bg-white logo-result px-3 py-2'>
                    <img src='https://res.cloudinary.com/agridential/image/upload/v1619159240/AGDImage/image_1_fuwspq.png' alt='sb' style={{ marginRight: '20px', height: '40px' }} />
                    <img src='https://res.cloudinary.com/agridential/image/upload/v1570695532/Logo/vbcblack_voirvu.png' alt='vbc' style={{ height: '30px' }} />
                </div>
                <Container className='h-100'>
                    <Row className='h-100'>
                        <Col md='6' className='d-flex justify-content-center align-items-center flex-column'>
                            <div className='bg-random-number_v2 d-flex justify-content-center align-items-center'>
                                {showResult ? <>
                                    <Confetti active={confettiState} />
                                    <p className='result-text'>{result}</p>
                                </>
                                    :
                                    <TextLoop
                                        className="draw-text"
                                        interval={100}
                                        springConfig={{ stiffness: 180, damping: 8 }}
                                        children={array}
                                    />}
                            </div>
                            <Button className='btn-block btn-golden' size='lg' disabled={drawState || array.length <= 1} onClick={handleDraw}>
                                {!drawState ? 'Draw' : "Drawing"}
                            </Button>

                        </Col>
                        <Col md='6' className='d-flex justify-content-center align-items-center flex-column'>
                            <img src={require('../../assets/img/bka/winner.png')} alt='winner-title' className="mb-2" />
                            <div className='bg-result_v2 text-white p-3'>
                                {listWinner.map((item, idx) => (
                                    idx < 5 ?
                                        <div className='my-2'>
                                            <Card key={idx} className='p-2 font-small'>
                                                {/* <span className='font-weight-bold text-dark  text-three-dots'>
                                                    <span className='text-black-50'>Blockchain address:</span> {item.bc_address}</span> */}
                                                <div className='w-100 d-flex'>
                                                    <span className='font-weight-bold text-dark'><span className='text-black-50'>Name:</span> {item.name}</span>
                                                    <span className='font-weight-bold text-dark ml-2'><span className='text-black-50'>Course:</span> {item.course}</span>
                                                </div>
                                                <div className='dropdown-divider'></div>
                                                <span className='font-weight-bold text-dark text-three-dots cursor-pointer'><span className='text-black-50'>Lucky Tx:</span> <span className='text-info'>{item.tx_id}</span></span>
                                            </Card>
                                        </div> : null
                                ))}
                                <span className='font-weight-bold'><span className='white'>Number of participants remaining: {array.length}</span></span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        listVote: state.listVote,
        userInfo: state.userInfo,
        pointAll: state.chartData,
        pathWebsite: state.pathWebsite.data,
        ballotDetail: state.ballotInfo.data,
        drawList: state.drawList,
        listWinnerStore: state.listWinner.data
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPointAll: (id) => dispatch({ type: "GET_POINT_ALL", id: id }),
        getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id }),
        getBallotDetail: (id) => dispatch({ type: "GET_BALLOT_DETAIL", id: id }),
        saveDrawList: (data, dataFull) => dispatch({ type: 'SAVE_DRAW_LIST', data: data, dataFull: dataFull }),
        saveListWinner: (data) => dispatch({ type: SAVE_LIST_WINNER, data: data }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Result);