const initialState = { data: [], data2: []}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_POINT_ALL":
      return {
        ...state
      }
    case "GET_POINT_ALL_SUCCESS":
      return {
        ...state,
        data: action.data,
        data2: action.data2 ? action.data2: []
      }
    case "GET_POINT_ALL_FAILURE":
      return {
        ...state,
      }
    default:
      return state
  }
}
export default reducer
