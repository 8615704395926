import axios from "axios"
import { LINK_API, LOADING } from "constants/API.js"
import { useFormik } from "formik"
import React from "react"
import SweetAlert from 'react-bootstrap-sweetalert'
import { Button, Card, Input } from "reactstrap"
import { connect } from "react-redux"
import * as Yup from "yup"
import { withRouter } from "react-router-dom"
import cookie from "react-cookies"
const EthereumTx = require('ethereumjs-tx');
const Form = (props) => {
    
    const stateCheckedin = cookie.load('checkYearEnd')
    const history = props.history;
    React.useEffect(()=>{
        if(stateCheckedin){
           history.push('/vbc/mobileresult')
        }
    },[stateCheckedin, history])

    const phone_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    const voteId = '0x73370ffc513b2d3849e618943255270f68a8c265';
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvd25lZEJ5IjoiTTAwMDk0NCIsInV1aWQiOiJNMDAwOTQ0IiwiYmNBZGRyZXNzIjoiMHg2NzYzMTg1NzRiZDI5YTdkZDgxZmU2ZWM1NWE2YzM4N2M2YzU1YWFkIiwicGhvbmUiOiIrODQgMzk0IDI1NCA0NjMiLCJlbWFpbCI6InBva2VkZXguYWdyaWRlbnRpYWxAZ21haWwuY29tIiwiaWF0IjoxNjExNzMzMDM2LCJleHAiOjE2MTk1MDkwMzZ9.LQyi7JzVFMs_Pm-Uo_EuFfOG0nfv2n_IDD6yu_Mm8SU'
    const [alert, setAlert] = React.useState(null)
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Required Information'),
            phone: Yup.string().matches(phone_regex, 'Invalid phone number')
                .required('Required Information'),

        }),
        onSubmit: async values => {
            handleSubmitData(values);
        },
    });
    const handleSubmitData = (data) => {
        let temp = [{
            "name": data.name,
            "description": data.phone
        }]
        handleAddCandidate(temp)

    }
    const handleAddCandidate = (listCandidate) => {
        handleLoading();
        axios.defaults.headers.common.Authorization = token

        const data = {
            "candidates": listCandidate,
            "vote_id": voteId,
            "vote_type": 0
        }
        axios.post(LINK_API.ADD_CANDIDATE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    handelSendRawAddCandidate(res.data.data)
                } else {
                    handleFailure();
                }
            })
            .catch(err => {
                handleFailure();
            })
    }
    const handleLoading = () => {
        setAlert(<SweetAlert
            warning
            customClass='bg-dark-popup'
            style={{ display: "block" }}
            title={"Processing..."}
            onConfirm={hideAlert}
            onCancel={hideAlert}
            showConfirm={false}
        >
            <img alt="loading" src={LOADING} />
        </SweetAlert>)
    }
    const handleFailure = () => {
        setAlert(
            <SweetAlert
                error
                style={{ display: "block" }}
                title='Failed to check in'
                onConfirm={hideAlert}
                onCancel={hideAlert}
                showConfirm={true}
            >
            </SweetAlert>
        )
    }
    const handleSuccess = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block" }}
                title='Checked in'
                onConfirm={hideAlert}
                onCancel={hideAlert}
                showConfirm={false}
            >
                <div className='w-100 text-center'>
                    <Button color='primary' onClick={handleChangePage}>Confirm</Button>
                </div>
            </SweetAlert>
        )
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const handleChangePage = () => {
        cookie.save("checkYearEnd", true, { path: "/" });
        props.history.push('/vbc/mobileresult')
    }
    const handelSendRawAddCandidate = (data) => {
        const privatekey_slice = "f205d4ad38ebf4303e08ea51c814c8d34a8fcd2da5a2564d351e5134d4a53bf5"
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
        const tx1 = new EthereumTx(data.configVoteRawTx, { chain: 4 });
        tx1.sign(Buffer_privatekey);
        const tx2 = new EthereumTx(data.addCandidateRawTx, { chain: 4 });
        tx2.sign(Buffer_privatekey);
        const data_raw = {
            ...data,
            addCandidateRawTx: '0x' + tx2.serialize().toString('hex'),
            configVoteRawTx: '0x' + tx1.serialize().toString('hex'),
            "vote_type": 0
        }
        axios.post(LINK_API.SEND_RAW_CANDIDATE, data_raw)
            .then(res => {
                if (res.data.responseCode === 1) {
                    handleSuccess();
                } else {
                    handleFailure();
                }
            })
            .catch(err => {
                handleFailure();
            })
    }
    return (
        <>
            <Card className='w-100 p-4'>
                <div className='d-flex justify-content-center'>
                    <img src={require('../../assets/img/vbcblack.png')} style={{ height: "50px" }} alt='logo' />
                </div>
                <div className='pt-3'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='form-row'>
                            <div className='form-group col-md-12'>
                                <label className='font-weight-bold'>Full name</label>
                                <Input
                                    placeholder='Your full name'
                                    type="text"
                                    name="name"
                                    maxLength={1000}
                                    value={formik.values.name}
                                    valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                    invalid={formik.touched.name && formik.errors.name ? true : false}
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col-md-12'>
                                <label className='font-weight-bold'>Phone number</label>
                                <Input
                                    placeholder='Your phone number'
                                    name="phone"
                                    maxLength={1000}
                                    value={formik.values.phone}
                                    valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                                    invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                    {...formik.getFieldProps('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col-md-12'>
                                <Button className='btn-block' color='primary'>Confirm</Button>
                            </div>
                        </div>
                    </form>
                </div>
                {alert}
            </Card>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord })

    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
