/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
import DropZone from "components/DropZone/index"
import axios from "axios"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Badge,
    UncontrolledTooltip
} from "reactstrap";
// core components
import UserHeader from "components/Headers/Header.js";
import { withTranslation } from 'react-i18next';
import ReactTable from "react-table-6";
import Pagination from "components/Pagination/index.js";
import { LINK_API, LOADING } from "constants/API.js"
import SweetAlert from 'react-bootstrap-sweetalert';
import DateTimePicker from 'react-datetime-picker';
import SuccessImg from "assets/img/success.gif"

import "./styles/dateTime.css"

const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameVote: "",
            desVote: "",
            candidates: [],
            openModal: false,
            data: [],
            alert: null,
            startTime: "",
            endTime: "",
            listImage: [],
            deleteImage: [],
            addImage: [],

            changeInfoStatus: false, //Check kiểm tra xem người dùng có thay đổi info của cuộc thi hay không
        }
        this.handleProcessData = this.handleProcessData.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleAddNewCand = this.handleAddNewCand.bind(this);
        this.handleSubmitVote = this.handleSubmitVote.bind(this);
        this.genTable = this.genTable.bind(this);
        this.handleRemoveCand = this.handleRemoveCand.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleFailure = this.handleFailure.bind(this);
        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
        this.handleSubmitCand = this.handleSubmitCand.bind(this);
        this.handleSendRawCand = this.handleSendRawCand.bind(this);
        this.handleChangeStatusCand = this.handleChangeStatusCand.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
        this.handleProcessImageVote = this.handleProcessImageVote.bind(this);
        this.handleAddImageCand = this.handleAddImageCand.bind(this);
        this.handleSubmitImageCand = this.handleSubmitImageCand.bind(this);
    }
    componentDidMount() {
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.id) {
                    this.props.getBallot(this.props.match.params.id)
                }
            }
        }
        if (this.props.userInfo.data) {
            axios.defaults.headers.common.Authorization = this.props.userInfo.data.token
        }
    }
    componentDidUpdate(preProps) {
        if (this.props.ballotInfo.data !== preProps.ballotInfo.data) {
            if (this.props.ballotInfo.data) {
                this.handleProcessData(this.props.ballotInfo.data)
                if (this.props.ballotInfo.data.candidates.length > 0) {
                    this.genTable(this.props.ballotInfo.data.candidates);
                }
            }
        }
    }
    handleSubmitImageCand(data) {
        this.handleLoading();
        let data_temp = [];
        data_temp.push(data[0].src)
        const data_send = {
            "vote_id": this.props.match.params.id,
            "candidate_id": this.state.imageCandId,
            "images": data_temp
        }
        axios.post(LINK_API.ADD_CAND_IMAGE, data_send)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.handleSuccess();
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleAddImageCand(id) {
        this.setState({
            imageCandId: id,
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block" }}
                    title={this.props.t('image')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                >
                    {"Id: " + id}
                    <DropZone max={1} onAdd={this.handleSubmitImageCand} onDelete={() => console.log("Delete")} list={[]} />
                    <Button color="danger" onClick={this.hideAlert} style={{ marginTop: "10px" }}>{this.props.t('close')}</Button>
                </SweetAlert>
            )
        })
    }
    handleProcessImageVote() {
        if (this.state.deleteImage.length > 0) {
            let data_delete = {
                "vote_id": this.props.match.params.id,
                "images": this.state.deleteImage
            }
            axios.post(LINK_API.DELETE_IMAGE_VOTE, data_delete)
                .then(res => {
                    if (res.data.responseCode === 1) {
                        if (this.state.addImage.length > 0) {
                            let data_add = [];
                            for (let j = 0; j < this.state.addImage.length; j++) {
                                data_add.push(this.state.addImage[j].src)
                            }
                            let data_send_add = {
                                "vote_id": this.props.match.params.id,
                                "images": data_add
                            }
                            axios.post(LINK_API.ADD_IMAGE_VOTE, data_send_add)
                                .then(res => {
                                    if (res.data.responseCode === 1) {
                                        this.handleSuccess()
                                    } else {
                                        this.handleFailure();
                                    }
                                })
                                .catch(err => {
                                    this.handleFailure();
                                })
                        } else {
                            this.handleSuccess();
                        }
                    } else {
                        this.handleFailure()
                    }
                })
                .catch(err => {
                    this.handleFailure();
                })
        } else {
            if (this.state.addImage.length > 0) {
                let data_add = [];
                for (let j = 0; j < this.state.addImage.length; j++) {
                    data_add.push(this.state.addImage[j].src)
                }
                let data_send_add = {
                    "vote_id": this.props.match.params.id,
                    "images": data_add
                }
                axios.post(LINK_API.ADD_IMAGE_VOTE, data_send_add)
                    .then(res => {
                        if (res.data.responseCode === 1) {
                            this.handleSuccess()
                        } else {
                            this.handleFailure();
                        }
                    })
                    .catch(err => {
                        this.handleFailure();
                    })
            } else {
                this.handleSuccess();
            }
        }
    }
    handleAddImage(data) {
        this.setState({
            addImage: data
        })
    }
    handleDeleteImage(data) {
        let temp_data = this.state.listImage;
        for (let i = 0; i < data.length; i++) {
            temp_data = temp_data.filter(item => item.url !== data[i])
        }
        this.setState({
            deleteImage: data,
            listImage: temp_data
        })
    }
    handleChangeStatusCand(status, id) {
        this.handleLoading();
        const data = {
            "vote_id": this.props.match.params.id,
            "candidate_id": id,
            "status": status
        }
        axios.put(LINK_API.UPDATE_STATUS_CANDIDATE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.handleSuccess();
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleSubmitCand(list) {
        for (let i = 0; i < list.length; i++) {
            list[i].status = "A"
        }
        const data = {
            "vote_id": this.props.match.params.id,
            "vote_type": 0,
            "candidates": list
        }
        axios.post(LINK_API.ADD_CANDIDATE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    this.handleSendRawCand(res.data.data, data.candidates)
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleSendRawCand(data, candidatesList) {
        var bytes = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.pass);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2, privateKey.length);
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
        const tx1 = new EthereumTx(data.configVoteRawTx, { chain: 4 });
        tx1.sign(Buffer_privatekey);
        const tx2 = new EthereumTx(data.addCandidateRawTx, { chain: 4 });
        tx2.sign(Buffer_privatekey);
        const data_raw = {
            ...data,
            configVoteRawTx: '0x' + tx1.serialize().toString('hex'),
            addCandidateRawTx: '0x' + tx2.serialize().toString('hex'),
        }
        axios.post(LINK_API.SEND_RAW_CANDIDATE, data_raw)
            .then(res => {
                if (res.data.responseCode === 1) {
                    if (this.state.deleteImage.length === 0 && this.state.addImage.length === 0) {
                        this.handleSuccess();
                    } else {
                        this.handleProcessImageVote();
                    }
                } else {
                    this.handleFailure();
                }
            })
            .catch(err => {
                this.handleFailure();
            })
    }
    handleChangeStartTime(date) {
        //  console.log("Date", Date.parse(date))

        this.setState({
            startTime: date,
            changeInfoStatus: true
        })
    }
    handleChangeEndTime(date) {
        this.setState({
            endTime: date,
            changeInfoStatus: true
        })
    }
    handleLoading() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    customClass='bg-dark-popup'
                    style={{ display: "block" }}
                    title={<span className='text-white'>{this.props.t('processing')}</span>}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                >
                    <img alt="loading" src={LOADING} />
                </SweetAlert>
            )
        })
    }
    handleSuccess() {
        this.setState({
            alert: (
                <SweetAlert
                    // success
                    customClass='bg-dark-popup'
                    style={{ display: "block" }}
                    title={""}
                    onConfirm={this.handleChangePage}
                    onCancel={this.hideAlert}
                    showConfirm={false}
                    showCancel={false}
                >
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img src={SuccessImg} alt='success' style={{ height: '200px', width: '200px' }} />
                        <span className='text-primary success-title'>{this.props.t("success")}</span>

                        <Button color="primary" className='btn-gradient-info' onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
                    </div>
                </SweetAlert>
            )
        })
    }
    handleFailure() {
        this.setState({
            alert: (
                <SweetAlert
                    error
                    style={{ display: "block" }}
                    title={this.props.t('failure')}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    showConfirm={true}
                >
                </SweetAlert>
            )
        })
    }
    hideAlert() {
        this.setState({
            alert: null,
            changeInfoStatus: false,
            addImage: [],
            deleteImage: []
        })
        this.props.getBallot(this.props.match.params.id)
    }
    handleSubmitVote() {
        const data = {
            "vote_id": this.props.match.params.id,
            "name": this.state.nameVote,
            "description": this.state.desVote,
            "startTime": Date.parse(this.state.startTime),
            "endTime": this.state.endTime ? Date.parse(this.state.endTime) : -1,
            "voting_type": 1,
        }
        if (this.state.changeInfoStatus === true) {
            this.handleLoading();
            axios.put(LINK_API.CREATE_VOTE, data)
                .then(res => {
                    if (res.data.responseCode === 1) {
                        const data_temp = this.state.data.filter(item => item.status === "N")
                        if (data_temp.length > 0) {
                            this.handleSubmitCand(data_temp)
                        } else {
                            this.handleSuccess();
                        }
                    } else {
                        this.handleFailure();
                    }
                })
                .catch(err => {
                    this.handleFailure();
                })
        } else {
            this.handleLoading();
            const data_temp = this.state.data.filter(item => item.status === "N")
            if (data_temp.length > 0) {
                this.handleSubmitCand(data_temp)
            } else {
                this.handleProcessImageVote();
            }
        }
    }
    handleRemoveCand(index) {
        const data = this.state.data.filter((item, idx) => idx !== index);
        this.genTable(data);
        this.setState({
            data: data
        })
    }
    genTable(data) {
        this.setState({
            data: data,
            candidates: data.map((item, index) => ({
                ...item,
                ava: item.image.length > 0 ?
                    <div style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundImage: `url(${item.image[0].url})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        marginLeft: "45%"
                    }}>

                    </div> : null,
                status: item.status === "A" ?
                    <Badge color="" className="badge-dot mr-4">
                        <i className="bg-success" />
                        {this.props.t('addNewVote.active')}
                    </Badge>
                    : item.status === "D" ?
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-danger" />
                            {this.props.t('addNewVote.deactive')}
                        </Badge>
                        :
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-info" />
                            {this.props.t('addNewVote.addNew')}
                        </Badge>,
                actions: item.status === "A" ?
                    <div>
                        <i className="ni ni-lock-circle-open text-red" id="deactiveTooltip" style={{ fontSize: "18px" }} onClick={() => this.handleChangeStatusCand("D", item.candidate_id)} />
                        <UncontrolledTooltip placement="right" target="deactiveTooltip">
                            {this.props.t('addNewVote.doDeactive')}
                        </UncontrolledTooltip>
                        <i className="ni ni-album-2 text-success" id="addImage" style={{ fontSize: "18px", marginLeft: "10px" }} onClick={() => this.handleAddImageCand(item.candidate_id)} />
                        <UncontrolledTooltip placement="right" target="addImage">
                            {this.props.t('image')}
                        </UncontrolledTooltip>
                    </div>
                    : item.status === "D" ?
                        <div>
                            <i className="ni ni-key-25 text-success" id="activeTooltip" style={{ fontSize: "18px" }} onClick={() => this.handleChangeStatusCand("A", item.candidate_id)} />
                            <UncontrolledTooltip placement="right" target="activeTooltip">
                                {this.props.t('addNewVote.doActive')}
                            </UncontrolledTooltip>
                            <i className="ni ni-album-2 text-success" id="addImage" style={{ fontSize: "18px", marginLeft: "10px" }} onClick={() => this.handleAddImageCand(item.candidate_id)} />
                            <UncontrolledTooltip placement="right" target="addImage">
                                {this.props.t('image')}
                            </UncontrolledTooltip>
                        </div>
                        :
                        <div>
                            <i className="ni ni-fat-remove text-red" id="deleteTooltip" style={{ fontSize: "20px" }} onClick={() => this.handleRemoveCand(index)} />
                            <UncontrolledTooltip placement="right" target="deleteTooltip">
                                {this.props.t('addNewVote.deleteCand')}
                            </UncontrolledTooltip>
                        </div>
            }))
        })
    }
    handleChange = name => async event => {
        const valid = name + "Valid";
        const inValid = name + "InValid"
        await this.setState({
            [name]: event.target.value,
            [valid]: true,
            [inValid]: false,
            changeInfoStatus: true
        })
    }
    async handleAddNewCand() {
        let data = this.state.data;
        await data.push({
            "name": this.state.candName,
            "description": this.state.candDes,
            "status": "N",
            "image": []
        })
        await this.genTable(data)
        this.setState({
            openModal: false,
            candName: "",
            candDes: ""
        })
    }
    handleProcessData(data) {
        this.setState({
            nameVote: data.name,
            desVote: data.description,
            startTime: new Date(data.startTime),
            endTime: data.endTime !== -1 ? new Date(data.endTime) : "",
            listImage: data.images
        })
    }
    handleCloseModal() {
        this.setState({
            openModal: false
        })
    }
    handleOpenModal() {
        this.setState({
            openModal: true
        })
    }
    handleToggle() {
        this.setState({
            openModal: !this.state.openModal
        })
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <UserHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="8">
                            <Card className="shadow-card-dark">
                                <CardHeader className="bg-transparent border-0">
                                    <Row className="align-items-center">
                                        <Col xs="12">
                                            <h3 className="mb-0 text-white">{t('addNewVote.info')}</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form className='form-login'>
                                        <div>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label text-white"
                                                            htmlFor="input-username"
                                                        >
                                                            {t('addNewVote.name')}
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-username"
                                                            placeholder={t('addNewVote.name')}
                                                            type="text"
                                                            value={this.state.nameVote || ''}
                                                            onChange={this.handleChange("nameVote")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label text-white"
                                                    htmlFor="input-username">{t('profile.about')}</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    placeholder={t('addNewVote.fewDes')}
                                                    rows="4"
                                                    type="textarea"
                                                    value={this.state.desVote || ''}
                                                    onChange={this.handleChange("desVote")}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label text-white"
                                                            htmlFor="input-username">{t('addNewVote.startTime')}</label>
                                                        <DateTimePicker
                                                            onChange={this.handleChangeStartTime}
                                                            value={this.state.startTime}
                                                            format="d-M-yy"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label text-white"
                                                            htmlFor="input-username">{t('addNewVote.endTime')}</label>
                                                        <DateTimePicker
                                                            onChange={this.handleChangeEndTime}
                                                            value={this.state.endTime}
                                                            format="d-M-yy"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="order-xl-1" xl="4">
                            <Card className="shadow-card-dark">
                                <CardHeader className="bg-transparent border-0">
                                    <Row className="align-items-center">
                                        <Col xs="12">
                                            <h3 className="mb-0 text-white">{t('image')}</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <DropZone onAdd={this.handleAddImage} onDelete={this.handleDeleteImage} list={this.state.listImage} max={3 - this.state.listImage.length} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "30px" }}>
                        <div className="col">
                            <Card className="shadow-card-dark px-2">
                                <CardHeader className="border-0 bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0 text-white">{t('addNewVote.candList')}</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                                className='btn-gradient-info'
                                                href="#pablo"
                                                onClick={this.handleOpenModal}
                                            >
                                                {t('add')}
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <ReactTable
                                    data={this.state.candidates}
                                    previousText="<"
                                    nextText=">"
                                    rowsText="hàng"
                                    ofText="của"
                                    pageText="Trang"
                                    noDataText={t('addNewVote.noCand')}
                                    columns={[
                                        {
                                            Header: t('addNewVote.candName'),
                                            accessor: "name",
                                            filterable: false
                                        },
                                        {
                                            Header: t('image'),
                                            accessor: "ava",
                                            filterable: false
                                        },
                                        {
                                            Header: t('addNewVote.des'),
                                            accessor: "description",
                                            filterable: false
                                        },
                                        {
                                            Header: t('addNewVote.status'),
                                            accessor: "status",
                                            filterable: false
                                        },
                                        {
                                            Header: t('actions'),
                                            accessor: "actions",
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    sortable={false}
                                    resizable={false}
                                    className="-striped -highlight"
                                    PaginationComponent={Pagination}
                                />
                            </Card>
                        </div>
                    </Row>
                    <Modal zIndex={2000} centered={true} backdrop={true} backdropClassName='modal-backdrop' isOpen={this.state.openModal} toggle={this.handleToggle} className=' overflow-hidden'>
                        <ModalHeader className='bg-dark-modal' toggle={this.handleToggle}><span className='text-white'>{t('addNewVote.newCand')}</span></ModalHeader>
                        <ModalBody className='bg-dark-modal'>
                            <Form className='form-login'>
                                <div>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label text-white"
                                                    htmlFor="input-username"
                                                >
                                                    {t('addNewVote.candName')}
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-username"
                                                    placeholder={t('addNewVote.candName')}
                                                    type="text"
                                                    value={this.state.candName || ''}
                                                    onChange={this.handleChange("candName")}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <FormGroup>
                                        <label
                                            className="form-control-label text-white"
                                            htmlFor="input-username">{t('profile.about')}</label>
                                        <Input
                                            className="form-control-alternative"
                                            placeholder={t('addNewVote.fewDesCand')}
                                            rows="4"
                                            type="textarea"
                                            value={this.state.candDes || ''}
                                            onChange={this.handleChange("candDes")}
                                        />
                                    </FormGroup>
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter className='bg-dark-modal'>
                            <Button color="primary" className='btn-gradient-info' onClick={this.handleAddNewCand}>{t('add')}</Button>{' '}
                            <Button color="danger" onClick={this.handleCloseModal}>{t('cancle')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Row style={{ marginTop: "30px" }}>
                        <Col lg="12" className='text-right pb-3'>
                            <Button color="primary" className='btn-gradient-info' onClick={this.handleSubmitVote}>{t('confirm')}</Button>
                        </Col>
                    </Row>
                    {this.state.alert}
                </Container>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        ballotInfo: state.ballotInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord }),
        getBallot: (id) => dispatch({ type: "GET_BALLOT_DETAIL", id: id })
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Profile));
