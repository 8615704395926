/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
class AdminNavbar extends React.Component {
  constructor(props){
    super(props);
    this.state={
        lang: "en" ,
        isLogin: false
    }
    this.handleChangeLang  = this.handleChangeLang.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this)
    this.handleSignIn = this.handleSignIn.bind(this)
  }
  componentDidMount(){
   if(this.props.userInfo){
     if(this.props.userInfo.data){
       this.setState({
         isLogin: true,
         userName: this.props.userInfo.data.name
       })
     }
   }
  }
  componentDidUpdate(preProps){
    if(preProps.userInfo !== this.props.userInfo){
      if(this.props.userInfo.data){
        this.setState({
          isLogin: true,
          userName: this.props.userInfo.data.name
        })
      }else{
        this.setState({
          isLogin: false
        })
      }
    }
  }
  handleSignIn(){
    if(this.props.match.path === "/milktea/:id/:id2"){
      this.props.savePathWebsite(this.props.match.url)
    }
    if(this.props.match.path === "/employTea/:id"){
      this.props.savePathWebsite(this.props.match.url)
    }
    this.props.history.push("/auth/register")
  }
  async handleSignOut(){
    await this.props.cleanStore();
    window.location.reload();
  //this.props.history.push("/auth/register")
  }
  handleChangeLang(lang){
    this.props.i18n.changeLanguage(lang);
  }
  render() {
    const {t} = this.props;
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
          style={{ background: "#161C23"}}
        >
          <Container className="px-2">
            <NavbarBrand to="/" tag={Link}>
              <img alt="..." src={"https://res.cloudinary.com/agridential/image/upload/v1598339964/AGDImage/Asset_3_4x_bk7iqm.png"} style={{height: "50px"}} />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar-collapse-main">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="..."
                        src={"https://res.cloudinary.com/agridential/image/upload/v1598331136/AGDImage/Asset_2_palull.png"}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link-icon" onClick={() => window.open("https://smartballot.io/")}  target="_blank" tag={Link}>
                    <i className="ni ni-planet" style={{color: "white"}}/>
              <span className="nav-link-inner--text" style={{color: "white"}}>{t('nav.home')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                </NavItem>
              </Nav>
              <Nav  className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span >
                      <i className="ni ni-world" style={{color: "white"}}/>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold" style={{color: "white"}}>
                        {this.props.t('nav.lang')}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{this.props.t('nav.lang')}</h6>
                  </DropdownItem>
                  <DropdownItem onClick={() => this.handleChangeLang("vi")}>
                    <img src="https://res.cloudinary.com/agridential/image/upload/v1586405184/images/vietnam_v4oix1.png" alt="vi" />
                    <span style={{ marginLeft: "5px" }}>{t('lang.vi')}</span>
                  </DropdownItem>
                  <DropdownItem divider />

                  <DropdownItem onClick={() => this.handleChangeLang("en")}>
                    <img src="https://res.cloudinary.com/agridential/image/upload/v1586405184/images/usa-today_ypprq8.png" alt="en" />
                    <span style={{ marginLeft: "5px" }}>{t('lang.en')}</span>
                  </DropdownItem>
                 
                 
                 
                </DropdownMenu>
              </UncontrolledDropdown>
              {this.state.isLogin ? 
               <UncontrolledDropdown nav>
               <DropdownToggle className="pr-0" nav>
                 <Media className="align-items-center">
                   <span className="avatar avatar-sm rounded-circle">
                     <img
                       alt="..."
                       src={"https://res.cloudinary.com/agridential/image/upload/v1598336900/AGDImage/1396_zmkeyp.jpg"}
                     />
                   </span>
                   <Media className="ml-2 d-none d-lg-block">
                   <span className="mb-0 text-sm font-weight-bold text-warning">
                        {this.state.userName}
                      </span>
                   </Media>
                 </Media>
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-arrow" right>
                 <DropdownItem className="noti-title" header tag="div">
                   <h6 className="text-overflow m-0">{this.props.t('adminNav.welcome')}!</h6>
                 </DropdownItem>
                 <DropdownItem to="/admin/user-profile" tag={Link}>
                   <i className="ni ni-single-02" />
                   <span>{this.props.t('adminNav.profile')}</span>
                 </DropdownItem>
                 <DropdownItem divider />
                 <DropdownItem href="#pablo" onClick={this.handleSignOut}>
                   <i className="ni ni-user-run" />
                   <span>{this.props.t('adminNav.logout')}</span>
                 </DropdownItem>
               </DropdownMenu>
             </UncontrolledDropdown>:
             <Button color="warning" round style={{marginLeft: "30px"}} onClick={this.handleSignIn}>{this.props.t('login.login')}</Button>
              }
               </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord }),
    cleanStore: () => dispatch({type: "CLEAN_STORE"}),
    savePathWebsite: (data) => dispatch({ type: "SAVE_PATH_WEBSITE", data: data }),
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps )(withTranslation('common')(AdminNavbar)));
