/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
// reactstrap components
import {
    Col, Container, Navbar, NavbarBrand,





    Row
} from "reactstrap";
class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "vi"
        }
        this.handleChangeLang = this.handleChangeLang.bind(this);
    }
    componentDidMount() {
        if (this.props.userInfo.data) {
            if (this.props.match.path !== "/vote") {
                this.props.history.push("/admin/list")
            }
        }
    }
    handleChangeLang(lang) {
        this.props.i18n.changeLanguage(lang);
    }
    render() {
        return (
            <>
                <Navbar
                    className="navbar-top navbar-horizontal navbar-dark"
                    expand="md"
                >
                    <Container className="px-4">
                        <NavbarBrand to="/" tag={Link}>
                            <img alt="..." src={"https://res.cloudinary.com/agridential/image/upload/c_scale,h_30/v1583380497/images/Smart_Ballot_2x_1_wutmia.png"} />
                        </NavbarBrand>
                        <div className="navbar-collapse-header d-md-none">
                            <Row>
                                <Col className="collapse-brand" xs="12">
                                    <Link to="/">
                                        <img
                                            alt="..."
                                            src={"https://res.cloudinary.com/agridential/image/upload/c_scale,h_30/v1583380497/images/Smart_Ballot_2x_1_wutmia.png"}
                                        />
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Navbar>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord }),
        cleanStore: () => dispatch({ type: "CLEAN_STORE" })

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AdminNavbar)));
