const initialState = { data: '', pass: ""}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_BKA":
      return {
        ...state
      }
    case "SAVE_BKA_SUCCESS":
      return {
        ...state,
        data: action.data,
        pass: action.pass
      }
    case "SAVE_BKA_FAILURE":
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
