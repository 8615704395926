/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Col, Card, CardBody, Row, CardTitle } from "reactstrap";
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import styles from "./topVoteStyle"
import Medal1 from "assets/img/Medal1.svg"
import Medal2 from "assets/img/Medal2.svg"
import Medal3 from "assets/img/Medal3.svg"

import "./member.css"
import clsx from "clsx";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 10
    }
  }
  componentDidMount() {
    if (this.props.pointAll) {
      if (this.props.pointAll.data) {
        let temp = [];
        if(this.props.pointAll.data[1] && this.props.pointAll.data[0]){
          temp.push(this.props.pointAll.data[1]);
          temp.push(this.props.pointAll.data[0]);
          if(this.props.pointAll.data[2]){
            temp.push(this.props.pointAll.data[2]);
          }
        }else{
          if(this.props.pointAll.data[0]){
            temp.push(this.props.pointAll.data[0]);
          }
        }
        this.setState({
          data: temp
        })
      }
    }
    if (this.props.listVote) {
      if (this.props.listVote.data) {
        this.setState({
          total: this.props.listVote.data.length
        })
      }
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.pointAll !== nextProps.pointAll) {
      if (nextProps.pointAll.data) {
        let temp = [];
        if(nextProps.pointAll.data[1] && nextProps.pointAll.data[0]){
          temp.push(nextProps.pointAll.data[1]);
          temp.push(nextProps.pointAll.data[0]);
          if(nextProps.pointAll.data[2]){
            temp.push(nextProps.pointAll.data[2]);
          }
        }else{
          if(nextProps.pointAll.data[0]){
            temp.push(nextProps.pointAll.data[0]);
          }
        }
     
        this.setState({
          data: temp
        })

      }
    }
    if (nextProps.listVote !== this.props.listVote) {
      if (this.props.listVote) {
        if (this.props.listVote.data) {
          this.setState({
            total: this.props.listVote.data.length
          })
        }
      }
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Row className='mt-5'>
          {this.state.data.map((item, index) => {
            if (index < 3) {
              return (
                <Col lg="6" xl="4" className={clsx({'pt-4': index === 0, 'pt-5': index === 2})}>
                  <Card className="card-stats mb-4 mb-xl-0 shadow-card-dark position-relative ">
                    <div className='display-medal'>
                      <img src={index === 1 ? Medal1 : index === 0 ? Medal2 : Medal3} alt='medal1' style={{height: '100px'}}/>
                    </div>
                    <CardBody >
                      <div className='d-block'>
                      <div className="w-100 justify-content-center d-flex">
                          <div className={classes.avatar} style={{ backgroundImage: `url(${item.image[0] ? item.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1619502978/images/Asset_3_2x_xyyfup.png"})` }}>
                          </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                          <CardTitle
                            tag="h3"
                            className="text-uppercase  mb-0 text-primary mt-3"
                          >
                            {item.name}
                          </CardTitle>
                          <div className='d-flex flex-row justify-content-center align-items-center'>
                          <span className="h2 font-weight-bold mb-0 text-white">
                            {item.point}
                          </span>
                          <span className="text-nowrap text-muted" style={{ marginLeft: "5px" }}>lượt bình chọn</span>
                          </div>
                        </div>
                      </div>
                      <div className={"mt-3 mb-0 text-muted text-sm"}>
                        <span className="text-primary mr-2">
                          <i className="ni ni-like-2" style={{ marginRight: "5px" }} />
                          {item.point + " / " + this.state.total}
                        </span>{" "}
                        <span className="text-nowrap">Tổng số lượt bình chọn</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            } else {
              return null;
            }
          })}
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ballotDetail: state.ballotInfo,
    userInfo: state.userInfo,
    pathWebsite: state.pathWebsite.data,
    pointAll: state.pointAll,
    listVote: state.listVote,
  }
}
export default withStyles(styles)(connect(mapStateToProps, null)(Header));
