import Axios from "axios";
import { LINK_API } from "constants/API";
import React from "react";
import Confetti from "react-dom-confetti";
import { connect } from "react-redux";
import TextLoop from "react-text-loop";
import { Card, Col, Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";

import "./index.css";
const EthereumTx = require('ethereumjs-tx');
const Result = (props) => {
    const [array, setArray] = React.useState([]);
    //ballot vote detail and token of account to vote
    const voteId = '0xb2e4a41e955d5ba6d6b64a6e9747896d763249ec';
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvd25lZEJ5IjoiUzAwMDA2OSIsInV1aWQiOiJTMDAwMDY5IiwiYmNBZGRyZXNzIjoiMHg2MzJiZDFiNzAxMTljYjQ0MDc0OTQzMTA4YzBlNWFhZjE5ODc5MzE2IiwicGhvbmUiOiIrODQgMzk0IDI1NCA0MTMiLCJlbWFpbCI6ImthbmFob2tlYmFAZ21haWwuY29tIiwiaWF0IjoxNjExODA3OTg2LCJleHAiOjE2MTk1ODM5ODZ9.yEzunArV-hLV2snn8mNl7OBLvi2hfe_59PLimC1pB2U'
    const privatekey_slice = '1a1d741dac974ce9f6eb0b2d31a79ab3df01048576c62962f4e7e71b35bfb4da';

    const [allInfoCand, setAll] = React.useState([])
    const getListCand = props.getBallotDetail;
    const ballotDetail = props.ballotDetail;
    const saveDrawList = props.saveDrawList;
    const drawList = props.drawList;
    const getListVote = props.getListVote;

    React.useEffect(() => {
        getListVote('0xb2e4a41e955d5ba6d6b64a6e9747896d763249ec');
    }, [getListVote]);

    React.useEffect(() => {
        getListCand('0xb2e4a41e955d5ba6d6b64a6e9747896d763249ec');
    }, [getListCand]);

    React.useEffect(() => {
        let temp = [];
        if (ballotDetail) {
            if (ballotDetail.candidates)
                if (ballotDetail.candidates.length < drawList.dataFull.length || drawList.data.length === 0) {
                    for (let i = 0; i < ballotDetail.candidates.length; i++) {
                        if(ballotDetail.candidates[i].name !== 'Nguyễn Đức Huy'){
                            temp.push(ballotDetail.candidates[i].name);
                        }else{
                            ballotDetail.candidates[i].name = 'Bùi Đức Huy'
                            temp.push('Bùi Đức Huy');
                        }
                    }
                    setArray(temp);
                    setAll(ballotDetail.candidates);
                    saveDrawList(temp, ballotDetail.candidates)
                    setStateShow(false)
                }
        }
    }, [ballotDetail, saveDrawList, drawList])

    const [drawState, setDraw] = React.useState(false);

    const handleDraw = () => {
        setDraw(true);
        setStateShow(false);
        setConfetti(false);
        let timer = setTimeout(() => {
            //random data
            let maxItemIndex = array.length;
            const randomIndex = Math.floor(Math.random() * maxItemIndex);
            setResult(array[randomIndex]);
            handleVote(allInfoCand[randomIndex]);
            //setactive and unactive
            setDraw(false);
            setStateShow(true);
            setConfetti(true);
            clearTimeout(timer);

            //remove item drawed
            const copyCurrentItems = [...array];
            copyCurrentItems.splice(randomIndex, 1);
            
            //setArray(copyCurrentItems);
            //remove item list all info of candidate
            const copyCurrentItemsAll = [...allInfoCand];
            copyCurrentItemsAll.splice(randomIndex, 1);

            // setAll(copyCurrentItemsAll);
            saveDrawList(copyCurrentItems, copyCurrentItemsAll)
        }, 3000)
    }

    React.useEffect(() => {
        if (drawList) {
            if (drawList.data && drawList.dataFull) {
                if (drawList.data.length > 0) {
                    setArray(drawList.data);
                    setAll(drawList.dataFull);
                    if (array.length === 0) {
                        setStateShow(false);

                    }
                }
            }
        }
    }, [drawList, array])

    const [result, setResult] = React.useState('');
    const [showResult, setStateShow] = React.useState(true);
    const [confettiState, setConfetti] = React.useState(false);
    const [listWinner, setWinner] = React.useState([]);
    const listVote = props.listVote;

    React.useEffect(() => {
        if (listVote.data) {
            setWinner(listVote.data)
        }
    }, [listVote])

    const handleVote = (dataCand) => {
        Axios.defaults.headers.common.Authorization = token;
        const data = {
            "vote_id": voteId,
            "candidates": []
        };
        data.candidates.push(dataCand.candidate_id);
        Axios.post(LINK_API.GET_INFO_VOTE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
                    const tx = new EthereumTx(res.data.data.rawTx, { chain: 4 });
                    tx.sign(Buffer_privatekey);
                    const rawTx = '0x' + tx.serialize().toString('hex');
                    const data_raw = {
                        ...res.data.data,
                        rawTx: rawTx
                    }
                    Axios.post(LINK_API.VOTE, data_raw)
                        .then(res => {
                            if (res.data.responseCode === 1) {
                                props.getListVote(voteId)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            <div className='bg-yearend'>
                <div className='bg-white logo-result px-3 py-2'>
                    <img src='https://res.cloudinary.com/agridential/image/upload/v1570695532/Logo/vbcblack_voirvu.png' alt='vbc' style={{ height: '40px' }} />
                    <img src='https://res.cloudinary.com/agridential/image/upload/c_scale,h_40/v1583380457/images/Smart_Ballot_2x_1_gcfbwt.png' alt='sb' style={{ marginLeft: '20px' }} />
                </div>
                <Container className='h-100'>
                    <Row className='h-100'>
                        <Col md='6' className='d-flex justify-content-center align-items-center flex-column'>
                            <div className='bg-random-number d-flex justify-content-center align-items-center'>
                                {showResult ? <>
                                    <Confetti active={confettiState} />
                                    <p className='result-text'>{result}</p>
                                </>
                                    :
                                    <TextLoop
                                        className="draw-text"
                                        interval={100}
                                        springConfig={{ stiffness: 180, damping: 8 }}
                                        children={array}
                                    />}
                            </div>
                            <Button className='btn-block btn-golden' size='lg' disabled={drawState || array.length <= 1} onClick={handleDraw}>
                                {!drawState ? 'Draw' : "Drawing"}
                            </Button>

                        </Col>
                        <Col md='6' className='d-flex justify-content-center align-items-center flex-column'>
                            <img src={require('../../assets/img/winner.png')} alt='winner-title' />
                            <div className='bg-result text-white p-3'>
                                {listWinner.map((item, idx) => (
                                    idx < 5 ?
                                        <div className='my-2'>
                                            <Card key={idx} className='p-2 font-small'>
                                                <span className='font-weight-bold text-dark  text-three-dots'>
                                                    <span className='text-black-50'>Blockchain address:</span> {item.candidate.bc_address}</span>
                                                <div className='w-100 d-flex'>
                                                    <span className='font-weight-bold text-dark'><span className='text-black-50'>Name:</span> {item.candidate.name}</span>
                                                    <span className='font-weight-bold text-dark ml-2'><span className='text-black-50'>Phone number:</span> {item.candidate.description}</span>
                                                </div>
                                                <div className='dropdown-divider'></div>
                                                <span className='font-weight-bold text-dark text-three-dots cursor-pointer'><span className='text-black-50'>Lucky Tx:</span> <span className='text-info'>{item.tx_id}</span></span>
                                            </Card>
                                        </div> : null
                                ))}
                                <span className='font-weight-bold'><span className='white'>Number of participants remaining: {array.length}</span></span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        listVote: state.listVote,
        userInfo: state.userInfo,
        pointAll: state.chartData,
        pathWebsite: state.pathWebsite.data,
        ballotDetail: state.ballotInfo.data,
        drawList: state.drawList

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPointAll: (id) => dispatch({ type: "GET_POINT_ALL", id: id }),
        getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id }),
        getBallotDetail: (id) => dispatch({ type: "GET_BALLOT_DETAIL", id: id }),
        saveDrawList: (data, dataFull) => dispatch({ type: 'SAVE_DRAW_LIST', data: data, dataFull: dataFull })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Result);