import { SAVE_LIST_WINNER_FAILURE } from "actions"
import { SAVE_LIST_WINNER_SUCCESS } from "actions"
import { SAVE_LIST_WINNER } from "actions"

const initialState = { data: []}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LIST_WINNER:
      return {
        ...state
      }
    case SAVE_LIST_WINNER_SUCCESS:
      return {
        ...state,
        data: action.data,
      }
    case SAVE_LIST_WINNER_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
