import withStyles from '@material-ui/core/styles/withStyles';
import Axios from 'axios';
import { LINK_API } from 'constants/API';
import { motion } from "framer-motion";
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Modal, Row } from "reactstrap";
import ContentLoader from "./contentLoader";
import "./member.css";
import dashboardStyle from './memberStyle';

const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

class Result extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      nameModal: '',
      descriptionModal: '',
      nodeHeight: '',
      data: [],
      
      nameContest: "",
      timeContest: "",
      txContest: "",
      numberContest: "",
      avatarContest: "",
      listType: this.props.t('vote.detail'),
      idNext: "",
      idNow: ''
    }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handlePreVote = this.handlePreVote.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.handleVote = this.handleVote.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.hideAlertVote = this.hideAlertVote.bind(this)
    this.handleGetInfoContest = this.handleGetInfoContest.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleSavePath = this.handleSavePath.bind(this);
    this.handleNextVote = this.handleNextVote.bind(this);
    this.handleSavePathRegis = this.handleSavePathRegis.bind(this)
  }

  componentDidMount() {
    if(this.props.ballotDetail){
      if(this.props.ballotDetail.data){
        this.handleGetInfoContest(this.props.ballotDetail.data)
        if(this.props.ballotDetail.data.candidates){
        }
      }
    }
    if(this.props.match){
        if(this.props.match.params.id){
            this.props.getBallotDetail(this.props.match.params.id)
            this.props.getPointAll(this.props.match.params.id)
        /// this.props.getListVote(this.props.match.params.id)
        }
        if(this.props.match.params.id2){
           cookie.save("idNow", this.props.match.params.id, { path: "/" });
          this.setState({
            idNext: this.props.match.params.id2,
            idNow:  this.props.match.params.id,
          })
        }
    }
    if(this.props.pointAll){
      if(this.props.pointAll.data){
        this.setState({
          data: this.props.pointAll.data,
        })
    }
    }
    this.props.savePathWebsite("")
  }
  componentDidUpdate(nextProps){
    if(this.props.ballotDetail !== nextProps.ballotDetail){
      if(this.props.ballotDetail.data){
        this.handleGetInfoContest(this.props.ballotDetail.data)
      }
    }
    if(this.props.pointAll !== nextProps.pointAll){
      if(this.props.pointAll.data){
        this.setState({
          data: this.props.pointAll.data,
        })
        let x = this.props.pointAll.data;
        shuffleArray(x)
      }
    }
  }
  handleSavePath(){
    this.props.savePathWebsite(this.props.match.url)
    this.props.history.push("/auth/register")
  }
  handleSavePathRegis(){
    this.props.savePathWebsite(this.props.match.url)
    this.props.history.push("/auth/register")
  }
  handleGetInfoContest(data){
    this.setState({
      nameContest: data.name,
      numberContest: data.candidates.length,
      txContest: data.vote_id,
      timeContest: "Từ " + moment(data.startTime).format("DD/MM/YYYY hh:mm") + " tới " + moment(data.endTime).format("DD/MM/YYYY hh:mm"),
      avatarContest: data.images.length > 0 ?( data.images[0] ? data.images[0].url : ""): "",
      network: data.network
    })
  }
  hideAlertVote(){
    this.setState({
      alert: null
    })
  }
  handleClose() {
    this.setState({
      openModal: false
    })
  }
  handleChangeView(data){
    this.setState({
      listType: data
    })
  }
  handleOpen(name, des, id) {
    this.setState({
      openModal: true,
      nameVote: name,
      descriptionVote: des,
      idVote: id
    })
  }
  handleCheck(name, id){
    this.setState({
      idVote: id,
      nameVote: name,
      openModal: false
    })
    if(this.props.userInfo.data){
      if(this.props.userInfo.data.name){
        this.handlePreVote(name, id)
      }else{
        this.setState({
          alert:(
            <SweetAlert
            warning
            style={{ display: "block" }}
            title=""
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
            showCancel={false}
          >
           <p className={this.props.classes.sweetTitle}>{this.props.t('vote.login')}</p> 
             <Button color="danger" onClick={this.hideAlert}>{this.props.t('cancle')}</Button>
          <Button color="primary" onClick={this.handleSavePath}>{this.props.t('login.login')}</Button>
          </SweetAlert>
          )
        })
      }
    }else{
      this.setState({
        alert:(
          <SweetAlert
          warning
          style={{ display: "block" }}
          title={this.props.t('vote.login')}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
          showCancel={false}
        >
           <Button color="danger" onClick={this.hideAlert}>{this.props.t('cancle')}</Button>
         <Button color="primary" style={{marginLeft: "10px"}} onClick={this.handleSavePathRegis}>{this.props.t('login.login')}</Button>
        </SweetAlert>
        )
      })
    }
  }
  handleNextVote(){
    this.props.history.push('/employTea/' + this.state.idNext)
  }
  handleVote(id){
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={<span className='text-dark'>{this.props.t("processing")}</span>}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={false}
        >
          <img alt="loading" style={{height: "150px"}} src={'https://res.cloudinary.com/agridential/image/upload/v1659329991/images/fcc2ec0b9796f05a88a9ebe1508ba438_nlbmck.gif'}/>
        </SweetAlert>
      )
    })
    const data = {
      "vote_id": this.props.ballotDetail.data.vote_id,
     // "ballot_id": this.props.ballotDetail.data.ballot_id,
      "candidates": []
    }
    data.candidates.push(id || this.state.idVote)
    Axios.defaults.headers.common.Authorization = this.props.userInfo.data.token
    Axios.post(LINK_API.GET_INFO_VOTE, data)
    .then(res => {
      if(res.data.responseCode === 1){
        var bytes  = CryptoJS.AES.decrypt(this.props.userInfo.data.privateEncrypted.toString(), this.props.userInfo.pass);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2,privateKey.length);
        const Buffer_privatekey =   Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(res.data.data.rawTx, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        const data_raw = {
         ...res.data.data,
         rawTx: rawTx
        }
        Axios.post(LINK_API.VOTE, data_raw)
        .then(result => {
          if(result.data.responseCode === 1){
            this.setState({
              alert: (
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title={this.props.t("success")}
                  onConfirm={this.hideAlertVote}
                  onCancel={this.hideAlert}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  showConfirm={false}
                >
                  <Button color="success" onClick={this.handleNextVote}>Tiếp tục</Button>
                </SweetAlert>
              )
            })
          }else{
            this.setState({
              alert: (
                <SweetAlert
                  danger
                  style={{ display: "block" }}
                  title={this.props.t("failure")}
                  onConfirm={this.hideAlert}
                  onCancel={this.hideAlert}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  showConfirm={false}
                >
                  <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
                </SweetAlert>
              )
            })
          }
        })
        .catch(err=> {
          console.log(err)
          this.setState({
            alert: (
              <SweetAlert
                danger
                style={{ display: "block" }}
                title={this.props.t("failure")}
                onConfirm={this.hideAlert}
                onCancel={this.hideAlert}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                showConfirm={false}
              >
                <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
              </SweetAlert>
            )
          })
        })
      }else{
        this.setState({
          alert: (
            <SweetAlert
              danger
              style={{ display: "block" }}
              title={this.props.t("failure")}
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              showConfirm={false}
            >
              <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
            </SweetAlert>
          )
        })
      }
    })
    .catch(err=> {
      console.log(err);
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: "block" }}
            title={this.props.t("failure")}
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            showConfirm={false}
          >
             <Button color="primary" onClick={this.hideAlert}>{this.props.t('confirm')}</Button>
          </SweetAlert>
        )
      })
    })
  }
  handlePreVote(name, id){
    if(this.state.openModal){
      this.handleClose();
    }
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block" }}
         // title="Bạn chắc chắn chứ?"
          onConfirm={()=> this.handleVote(id)}
          onCancel={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.confirm 
          }
          cancelBtnCssClass={
            this.props.classes.cancle
          }
          showConfirm={false}
          showCancel={false}
          confirmBtnText={'Vote luôn'}
          cancelBtnText={'Suy nghĩ lại'}
        >
          {/* <p className={this.props.classes.votefor}>{name || this.state.nameVote}</p>
          <p className={this.props.classes.hightLight}>Mã số: </p>
          <p className={this.props.classes.hightLight} style={{marginBottom: "20px"}}>{id || this.state.idVote}</p> */}
          <div className='w-100 text-center d-flex flex-column justify-content-center align-items-center'>
            <img alt='loading' src='https://res.cloudinary.com/agridential/image/upload/v1659329685/images/b7961b1a6fd889d047252bc68a449bdf_vwn2ue.gif' style={{height: "300px"}}/>
            <p className="font-weight-bold text-xl text-dark">{'Thí chủ chắc là mình chọn món này đó hả?'}</p>
          </div>
          <Button color="danger" outline onClick={this.hideAlert}>{this.props.t('thinkMore')}</Button>
          <Button style={{marginLeft: "10px"}} color="warning" onClick={()=>this.handleVote(id || this.state.idVote)}>{this.props.t('voted')}</Button>
        </SweetAlert>
      )
    })
  }
  hideAlert(){
    this.setState({
      alert: null
    })
  }
  render() {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        
        <Container>
          {this.state.data.length === 0 ? 
            <Row style={{marginTop: "10px"}}>
              <Col xs={12} md={4}>
                <Card >
                  <CardBody>
                  <ContentLoader/>
                  </CardBody>
                </Card>
                </Col>
                <Col xs={12} md={4}>
                <Card >
                <CardBody>
                  <ContentLoader/>
                  </CardBody>
                </Card>
                </Col>
                <Col xs={12} md={4}>
                <Card >
                <CardBody>
                  <ContentLoader/>
                  </CardBody>
                </Card>
                </Col>
            </Row>: null}
         
          <Row className='pb-5'>
            {this.state.data.map((member, index) => (
             
              <Col key={index} xs={12} md={4} className={classes.paddingCol}>
                  <motion.div
               animate={{ scale: 1 }}
               initial={{ scale: 0}}
               transition={{ duration: 2 }}
               className="w-100"
             >
                <Card style={{background: '#f2a308'}} className='noneBorder'>
                  <div  style={{ 
                    borderRadius: '50%',
                    //backgroundImage: `url(${member.image.length > 0 ? member.image[0].url : "https://res.cloudinary.com/agridential/image/upload/c_scale,h_200/v1586890575/images/avatarSB_ctvncw.png"})`,
                    backgroundImage: `url(${'https://res.cloudinary.com/agridential/image/upload/v1659329695/images/48dd9205518817a15da835b8d101c8d2_wkikpd.gif'})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "330px", width: "100%", display: "block", borderTopLeftRadius: "0.375rem", borderTopRightRadius: "0.375rem", boxShadow: "0px 16px 24px rgba(96, 97, 112, 0.16), 0px 2px 8px rgba(40, 41, 61, 0.04)" }}>
                  </div>
                  {/* <div className={classes.votePie}>
                  <p className={classes.voteNumber}>{member.point}</p>
                    <p className={classes.voteLabel}>lượt</p>
                  </div> */}
                  <CardBody className="py-3">
                    <p className="font-weight-bold text-white text-lg">{t('milktea')+ ' ' + index}</p>
                    <div>
                      <p className="text-white">
                     {t('choice')}
                      </p>
                    </div>
                    <div className="text-center">
                    <Button  color="white" className="rounded" onClick={() => this.handleCheck(member.name, member.candidate_id, )}>{t('vote.vote')}</Button>
                    </div>
                  </CardBody>
                </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
        <Modal isOpen={this.state.openModal} toggle={this.handleClose}>
          <div className='modal-header'>
           
            <p
              className={classes.modaltitle}
              id='exampleModalLabel'
            >
              {this.state.nameVote}
            </p>
          </div>
          <div className='modal-body'>
            <p className={classes.modalBody}>{this.state.descriptionVote}</p>
          </div>
          <div className='modal-footer'>
            <div className='left-side'>
              <Button
                color='primary'
                type='button'
                onClick={() => this.handleCheck(this.state.nameVote, this.state.idVote)}
              >
                Bình chọn
              </Button>
            </div>
            <div className='divider' />
            <div className='right-side'>
              <Button color='danger' type='button' onClick={this.handleClose}>
                {this.props.t('cancle')}
              </Button>
            </div>
          </div>
        </Modal>
        {this.state.alert}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    ballotDetail: state.ballotInfo,
    userInfo: state.userInfo,
    pathWebsite: state.pathWebsite.data,
    pointAll: state.pointAll
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getBallotDetail: (id) => dispatch({ type: "GET_BALLOT_DETAIL", id: id }),
    getPointAll: (id) => dispatch({ type: "GET_POINT_ALL", id: id }),
    getListVote: (id) => dispatch({ type: "GET_LIST_VOTE", id: id }),
    getInfoWinner: (id) => dispatch({ type: "GET_INFO_WINNER", id: id }),
    savePathWebsite: (data) => dispatch({ type: "SAVE_PATH_WEBSITE", data: data }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(withTranslation('common')(Result))));
