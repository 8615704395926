/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// reactstrap components
import { Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, UncontrolledDropdown } from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: ""
    }
    this.handleProcessData = this.handleProcessData.bind(this);
    this.handleChangeLang = this.handleChangeLang.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }
  componentDidMount() {
    if (this.props.userInfo.data) {
      this.handleProcessData(this.props.userInfo.data)
    } else {
      this.props.history.push("/auth/register")
    }
  }
  componentDidUpdate(preProps) {
    if (preProps.userInfo.data !== this.props.userInfo.data) {
      if (this.props.userInfo.data) {
        this.handleProcessData(this.props.userInfo.data)
      }
    }
  }
  handleChangeLang(lang) {
    this.props.i18n.changeLanguage(lang);
  }
  handleProcessData(data) {
    this.setState({
      userName: data.name
    })
  }
  async handleSignOut() {
    await this.props.cleanStore();
    this.props.history.push("/auth/register")
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.t(`${this.props.brandText}`)}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span >
                      <i className="ni ni-world" />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.t('nav.lang')}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{this.props.t('nav.lang')}</h6>
                  </DropdownItem>
                  <DropdownItem onClick={() => this.handleChangeLang("vi")}>
                    <img src="https://res.cloudinary.com/agridential/image/upload/v1586405184/images/vietnam_v4oix1.png" alt="vi" />
                    <span style={{ marginLeft: "5px" }}>{this.props.t('lang.vi')}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.handleChangeLang("en")}>
                    <img src="https://res.cloudinary.com/agridential/image/upload/v1586405184/images/usa-today_ypprq8.png" alt="en" />
                    <span style={{ marginLeft: "5px" }}>{this.props.t('lang.en')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={"https://res.cloudinary.com/agridential/image/upload/v1619502978/images/Asset_3_2x_xyyfup.png"}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm text-white font-weight-bold">
                        {this.state.userName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{this.props.t('adminNav.welcome')}!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>{this.props.t('adminNav.profile')}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={this.handleSignOut}>
                    <i className="ni ni-user-run" />
                    <span>{this.props.t('adminNav.logout')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" })

  }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AdminNavbar));
