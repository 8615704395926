import { SAVE_LIST_CHECKIN } from "actions"
import axios from "axios"
import { LINK_API } from "constants/API.js"
import { useFormik } from "formik"
import moment from "moment"
import React from "react"
import cookie from "react-cookies"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Button, Input } from "reactstrap"
import Spinner from "reactstrap/lib/Spinner"
import * as Yup from "yup"

const EthereumTx = require('ethereumjs-tx');

const Form = (props) => {

    const stateCheckedin = cookie.load('checkYearEnd')
    const history = props.history;

    React.useEffect(() => {
        if (stateCheckedin) {
            history.push('/vbc/mobileresult')
        }
    }, [stateCheckedin, history])

    const voteId = '0x719ee13983e6f5281c6a2600e5fc67e97d28d63a';
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvd25lZEJ5IjoiTTAwMDk0NCIsInV1aWQiOiJNMDAwOTQ0IiwiYmNBZGRyZXNzIjoiMHg2NzYzMTg1NzRiZDI5YTdkZDgxZmU2ZWM1NWE2YzM4N2M2YzU1YWFkIiwicGhvbmUiOiIrODQgMzk0IDI1NCA0NjMiLCJlbWFpbCI6InBva2VkZXguYWdyaWRlbnRpYWxAZ21haWwuY29tIiwiaWF0IjoxNjE5MTY0MTUwLCJleHAiOjE2MjY5NDAxNTB9.LG47r3fGm3D_M-ewZy2DdAIF4UqBSGJc0yQuk7YYuTE'
    const formik = useFormik({
        initialValues: {
            name: '',
            course: '',
            qr: '',
            time: ''
        },
        validationSchema: Yup.object({
            qr: Yup.string(),
            name: Yup.string(),
            course: Yup.string(),
        }),
        onSubmit: async values => {
            if (values.qr) {
                // if (values.name && values.course) {
                if (values.name) {
                    handleSubmitData(values);
                    formik.resetForm();
                }
            }
            else {
                if (values.name) {
                    handleSubmitData(values);
                    formik.resetForm();

                }

            }
        },
    });
    const listCheckin = props.listCheckin;
    const [checked, setChecked] = React.useState([])
    const saveCheckedIn = props.saveCheckedIn;

    React.useEffect(() => {
        if (listCheckin) {
            setChecked(listCheckin);
            console.log(listCheckin)
        }
    }, [listCheckin])
    const handleSubmitData = (data) => {


        let temp = [{
            "name": data.name,
            "description": data.course
        }]
        if (checked.length === 0) {
            let arrayCheck = [];
            arrayCheck.push({
                "name": data.name,
                "description": data.course,
                "time": moment(new Date()).format('DD/MM/YYYY hh:mm:ss')
            })
            if (data.name) {
                props.saveBKA(data)
            }
            saveCheckedIn(arrayCheck)
            handleAddCandidate(temp)

        } else {
            let isExist = false;
            for (let i = 0; i < checked.length; i++) {
                if ((checked[i].name === data.name) && (checked[i].description === data.course)) {
                    isExist = true;
                    break;
                }
            }
            if (!isExist) {
                let arrayCheck = checked;
                arrayCheck.push({
                    "name": data.name,
                    "description": data.course,
                    "time": moment(new Date()).format('DD/MM/YYYY hh:mm:ss')
                })
                if (data.name) {
                    props.saveBKA(data)
                }
                saveCheckedIn(arrayCheck)
                handleAddCandidate(temp)
            } else {
                if (data.name) {
                    props.saveBKA({
                        name: "Khách đã Checked in",
                        course: ""
                    })
                }
            }
        }
    }

    const handleAddCandidate = (listCandidate) => {
        setLoading('loading');
        axios.defaults.headers.common.Authorization = token

        const data = {
            "candidates": listCandidate,
            "vote_id": voteId,
            "vote_type": 0
        }
        axios.post(LINK_API.ADD_CANDIDATE, data)
            .then(res => {
                if (res.data.responseCode === 1) {
                    handelSendRawAddCandidate(res.data.data, listCandidate)
                } else {
                    // handleFailure();
                }
            })
            .catch(err => {
                // handleFailure();
            })
    }


    const handelSendRawAddCandidate = (data, listCandidate) => {
        const privatekey_slice = "f205d4ad38ebf4303e08ea51c814c8d34a8fcd2da5a2564d351e5134d4a53bf5"
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
        const tx1 = new EthereumTx(data.configVoteRawTx, { chain: 4 });
        tx1.sign(Buffer_privatekey);
        const tx2 = new EthereumTx(data.addCandidateRawTx, { chain: 4 });
        tx2.sign(Buffer_privatekey);
        const data_raw = {
            ...data,
            candidates: listCandidate,
            addCandidateRawTx: '0x' + tx2.serialize().toString('hex'),
            configVoteRawTx: '0x' + tx1.serialize().toString('hex'),
            "vote_type": 0
        }

        axios.post(LINK_API.SEND_RAW_CANDIDATE, data_raw)
            .then(res => {
                if (res.data.responseCode === 1) {
                    // handleSuccess();
                    setLoading('none');
                    formik.resetForm();
                    document.getElementById("qr-input").focus();
                } else {
                    // handleFailure();
                }
            })
            .catch(err => {
                // handleFailure();
            })
    }


    const handleChangeQR = (event) => {
        formik.setFieldValue('qr', event.target.value);
        document.getElementById("time-qr").value = new Date();
    }


    React.useEffect(() => {
        setInterval(() => {
            let strTest = document.getElementById("qr-input").value;
            let timeQR = new Date(document.getElementById("time-qr").value);
            if (strTest) {
                try {

                    let currentTime = new Date();
                    let test = currentTime.getTime() - timeQR.getTime();
                    if (test > 500) {
                        let x = Buffer.from(strTest, 'hex');
                        let str = x.toString();
                        let stringArray = str.split(",");
                        if (stringArray[0] && stringArray[1]) {
                            // formik.setFieldValue('name', stringArray[0]);
                            // formik.setFieldValue('course', stringArray[1]);
                            // formik.handleSubmit();
                        } else {
                            if (stringArray[0]) {
                                // formik.setFieldValue('name', stringArray[0]);
                                // formik.handleSubmit();
                            }
                        }
                    }

                }
                catch (err) {
                    console.log(err)
                }
            }
        }, 1000)
    }, [])

    const [isLoading, setLoading] = React.useState('none');

    const handleExport = () => {
        console.log("List", checked)
        let tempExcel = checked;
        const csvString = [
            [
                "Name",
                "Course",
                "Checked in at"
            ],
            ...tempExcel.map(item => [
                item.name,
                item.description,
                item.time
            ])

        ]
            .map(e => e.join(","))
            .join("\n");
        let csvContent = csvString;
        var hiddenElement = document.createElement('a');
        hiddenElement.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'List_bka.csv';
        hiddenElement.click();
    }
    return (
        <>
            <div className='w-100 p-3 card-bottom-page '>
                <form onSubmit={formik.handleSubmit} className='d-flex w-100 justify-content-end'>
                    <div className='form-group col-md-2'>
                        <input id='time-qr' type='hidden' />
                        <Input
                            placeholder='QR Code value'
                            type="text"
                            name="qr"
                            id='qr-input'
                            disabled={isLoading === 'loading'}
                            autoFocus={true}
                            maxLength={1000}
                            onChange={handleChangeQR}
                            value={formik.values.qr}
                            valid={formik.touched.qr && formik.errors.qr ? false : (formik.values.qr ? true : false)}
                            invalid={formik.touched.qr && formik.errors.qr ? true : false}
                        />
                        {formik.touched.qr && formik.errors.qr ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.qr}</p>
                        ) : null}
                    </div>
                    <div className='form-group col-md-2'>
                        <Input
                            placeholder='Your full name'
                            type="text"
                            name="name"
                            disabled={isLoading === 'loading'}
                            maxLength={1000}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                        ) : null}
                    </div>
                    <div className='form-group col-md-2'>
                        <Input
                            placeholder='Course'
                            name="course"
                            maxLength={1000}
                            disabled={isLoading === 'loading'}
                            value={formik.values.course}
                            valid={formik.touched.course && formik.errors.course ? false : (formik.values.course ? true : false)}
                            invalid={formik.touched.course && formik.errors.course ? true : false}
                            {...formik.getFieldProps('course')}
                        />
                        {formik.touched.course && formik.errors.course ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.course}</p>
                        ) : null}
                    </div>
                    <div className='form-group col-md-1 d-flex align-items-center'>
                        <Button color='primary'> {isLoading === 'none' ? 'Confirm' : isLoading === 'loading' ? <Spinner size='sm' /> : null}</Button>
                    </div>
                    <div className='form-group col-md-1 d-flex align-items-center'>
                        <Button color='success' onClick={handleExport}>Export List</Button>
                    </div>
                </form>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        listCheckin: state.listCheckin.data
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord }),
        saveBKA: (data) => dispatch({ type: "SAVE_BKA", data: data }),
        saveCheckedIn: (data) => dispatch({ type: SAVE_LIST_CHECKIN, data: data })
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
