/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Col, Card, CardBody, Row, CardTitle } from "reactstrap";
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import styles from "./topVoteStyle"
import './topVote.css'
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom"
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            total: 10,
            total2: 10,
            oneState: false
        }
    }
    componentDidMount() {
        if (this.props.pointAll) {
            if (this.props.pointAll.data) {
                this.setState({
                    data: this.props.pointAll.data,
                })
            }
            if (this.props.pointAll.data2) {
                this.setState({
                    data2: this.props.pointAll.data2,
                })
            }
        }
        if (this.props.listVote) {
            if (this.props.listVote.data) {
                this.setState({
                    total: this.props.listVote.data.length
                })
            }
            if (this.props.listVote.data2) {
                this.setState({
                    total2: this.props.listVote.data2.length
                })
            }
        }
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.id === 'undefined') {
                    this.setState({
                        oneState: true
                    })
                }
            }
        }
    }
    componentDidUpdate(nextProps) {
        if (this.props.pointAll !== nextProps.pointAll) {
            if (this.props.pointAll.data) {
                this.setState({
                    data: this.props.pointAll.data,
                })
            }
            if (this.props.pointAll.data2) {
                this.setState({
                    data2: this.props.pointAll.data2,
                })
            }
        }
        if (nextProps.listVote !== this.props.listVote) {
            if (this.props.listVote) {
                if (this.props.listVote.data) {
                    this.setState({
                        total: this.props.listVote.data.length
                    })
                }
                if (this.props.listVote.data2) {
                    this.setState({
                        total2: this.props.listVote.data2.length
                    })
                }
            }
        }

    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <div>
                {this.state.oneState === true ? <h1 className='text-white'>Result</h1>  : <h1 className='text-white'>Top 3 Milk tea</h1>}
                </div>
                <Row>
                    {this.state.data.map((item, index) => {
                        if (index < 1) {
                            return (
                                <Col lg="6" xl="4" className='py-3'>
                                    <Card className="card-stats mb-4 mb-xl-0 bg-gradient-orangexxz">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0 text-white"
                                                    >
                                                        {item.name}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {item.point}
                                                    </span>
                                                    <span className="text-nowrap" style={{ marginLeft: "5px" }}>lượt bình chọn</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className={classes.avatar} style={{ backgroundImage: `url(${item.image[0] ? item.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1615629919/images/300_hfg6r1.png"})` }}>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-warning mr-2 bg-white rounded px-2 py-1">
                                                    <i className="ni ni-like-2" style={{ marginRight: "5px" }} />
                                                    {item.point + " / " + this.state.total}
                                                </span>{" "}
                                                <span className="text-nowrap text-white">Tổng số lượt bình chọn</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        } else {
                            return (
                                <Col lg="6" xl="4" className='py-3'>
                                    <Card className="card-stats mb-4 mb-xl-0 ">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-white mb-0"
                                                    >
                                                        {item.name}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {item.point}
                                                    </span>
                                                    <span className="text-nowrap" style={{ marginLeft: "5px" }}>{this.props.t('voteNumber')}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className={classes.avatar} style={{ backgroundImage: `url(${item.image[0] ? item.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1615629919/images/300_hfg6r1.png"})` }}>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-success mr-2">
                                                    <i className="ni ni-like-2" style={{ marginRight: "5px" }} />
                                                    {item.point + " / " + this.state.total}
                                                </span>{" "}
                                                <span className="text-nowrap">{this.props.t('allVotes')}</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        }
                    })}
                </Row>
                <div className='mt-5'>
                {this.state.oneState === true ? null :  <h1 className='text-white'>Top 3 Employee</h1>}
                </div>
                <Row>
                    {this.state.oneState === true ? null : this.state.data2.map((item, index) => {
                        if (index < 1) {
                            return (
                                <Col lg="6" xl="4" className='py-3'>
                                    <Card className="card-stats mb-4 mb-xl-0 bg-gradient-orangexxz">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0 text-white"
                                                    >
                                                        {item.name}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {item.point}
                                                    </span>
                                                    <span className="text-nowrap" style={{ marginLeft: "5px" }}>{this.props.t('voteNumber')}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className={classes.avatar} style={{ backgroundImage: `url(${item.image[0] ? item.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1615629919/images/300_hfg6r1.png"})` }}>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-warning mr-2 bg-white rounded px-2 py-1">
                                                    <i className="ni ni-like-2" style={{ marginRight: "5px" }} />
                                                    {item.point + " / " + this.state.total2}
                                                </span>{" "}
                                                <span className="text-nowrap text-white">{this.props.t('allVotes')}</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>

                            )
                        } else {
                            return (
                                <Col lg="6" xl="4" className='py-3'>
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        {item.name}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {item.point}
                                                    </span>
                                                    <span className="text-nowrap" style={{ marginLeft: "5px" }}>{this.props.t('voteNumber')}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className={classes.avatar} style={{ backgroundImage: `url(${item.image[0] ? item.image[0].url : "https://res.cloudinary.com/agridential/image/upload/v1615629919/images/300_hfg6r1.png"})` }}>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-success mr-2">
                                                    <i className="ni ni-like-2" style={{ marginRight: "5px" }} />
                                                    {item.point + " / " + this.state.total2}
                                                </span>{" "}
                                                <span className="text-nowrap">{this.props.t('allVotes')}</span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>

                            );
                        }
                    })}
                </Row>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        ballotDetail: state.ballotInfo,
        userInfo: state.userInfo,
        pathWebsite: state.pathWebsite.data,
        pointAll: state.pointAll,
        listVote: state.listVote,
    }
}
export default withRouter(withTranslation('common')(withStyles(styles)(connect(mapStateToProps, null)(Header))));
