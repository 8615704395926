/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/Header.js";
import { withTranslation } from 'react-i18next';
class Profile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: ""
    }
    this.handleProcessData = this.handleProcessData.bind(this)
  }
  componentDidMount(){
    if(this.props.userInfo.data){
      this.handleProcessData(this.props.userInfo.data)
    }
  }
  componentDidUpdate(preProps){
    if(preProps.userInfo.data !== this.props.userInfo.data){
      if(this.props.userInfo.data){
        this.handleProcessData(this.props.userInfo.data)
      }
    }
  }
  handleProcessData(data){
    this.setState({
      username: data.name,
      phone: data.phone,
      email: data.email
    })
  }
  render() {
    const {t} = this.props;
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={"https://res.cloudinary.com/agridential/image/upload/c_scale,h_180/v1586402039/images/avatarSB_bvu7ix.png"}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    {/* <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button> */}
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        {/* <div>
                          <span className="heading">22</span>
                          <span className="description">Friends</span>
                        </div>
                        <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div> */}
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                     {this.state.username}
                      {/* <span className="font-weight-light">, 27</span> */}
                    </h3>
                    {/* <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                    <hr className="my-4" />
                    <p>
                      Ryan — the name taken by Melbourne-raised, Brooklyn-based
                      Nick Murphy — writes, performs and records all of his own
                      music.
                    </p>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Show more
                    </a> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                  <h3 className="mb-0">{t('profile.account')}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        {t('profile.edit')}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {t('profile.userInfo')}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {t('profile.userName')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder={t('profile.userName')}
                              type="text"
                              value= {this.state.username || ''}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-company"
                            >
                              {t('profile.company')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-company"
                              placeholder=   {t('profile.company')}
                              type="text"
                              value= {this.state.company || ''}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-position"
                            >
                                {t('profile.position')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-position"
                              placeholder= {t('profile.position')}
                              type="text"
                              value= {this.state.position || ''}
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Jesse"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col> */}
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      {t('profile.contact')}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                                {t('profile.email')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-address"
                              placeholder=  {t('profile.email')}
                              type="email"
                              value= {this.state.email || ''}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                               {t('login.phone')}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-phone"
                              placeholder={t('login.phone')}
                              type="number"
                              value= {this.state.phone || ''}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">{t('profile.about')}</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>{t('profile.about')}</label>
                        <Input
                          className="form-control-alternative"
                          placeholder= {t('profile.fewWord')}
                          rows="4"
                          type="textarea"
                          value= {this.state.about || ''}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveInfo: (data, passWord) => dispatch({ type: "SAVE_USER_INFO", data: data, pass: passWord })

  }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Profile));
