import LOADING from "assets/img/loading/loadingBlock.gif"
const HOST = process.env.REACT_APP_ENV === 'dev' ? 'https://api.smartballot.io' : 'https://account.agridential.vn'

const LINK_API = {
  REGISTER: HOST + '/api/auth/signup/',
  LOGIN: HOST + '/api/auth/signin',
  GET_BALLOT_DETAIL: HOST + '/api/vote?vote_id=',
  GET_INFO_VOTE: HOST + '/api/voting',
  VOTE: HOST + "/api/voting/sendRawTxVoting",
  GET_POINT_ALL: HOST + '/api/vote/pointOfAll?vote_id=',
  GET_POINT_CANDIDATE: HOST + '/api/vote/point?vote_id=',
  GET_INFO_WINNER: HOST + '/api/vote/winner?vote_id=',
  GET_LIST_VOTING: HOST + '/api/voting/votingOfVote?vote_id=',
  GET_VOTE_LIST: HOST + "/api/vote/all",
  CREATE_VOTE: HOST + "/api/vote",
  SEND_RAW_CREATE_VOTE: HOST + "/api/vote/sendRawTxVote",
  ADD_CANDIDATE: HOST + "/api/vote/addCandidateToVote",
  SEND_RAW_CANDIDATE: HOST + "/api/vote/sendRawTxAddCandidate",
  UPDATE_STATUS_CANDIDATE: HOST + "/api/vote/setStatus",
  CHANGE_STATUS_VOTE: HOST + "/api/vote/status",
  ADD_IMAGE_VOTE: HOST + "/api/vote/addVoteImages",
  DELETE_IMAGE_VOTE: HOST + "/api/vote/deleteVoteImages",
  ADD_CAND_IMAGE: HOST + '/api/vote/addCandidateImages'
}


export {LINK_API, LOADING};
export default {LINK_API, LOADING};


