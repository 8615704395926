import React from "react"
import "./index.css"
import ProgressBar from 'react-animated-progress-bar';
import {connect} from "react-redux"
const Loading = (props) => {
    return (
        <>
            <div className='bg-loading position-relative'>
                <div className='d-flex justify-content-center align-items-center p-5'>
                    <img src={require("../../assets/img/logo_vbc.png")} className='logo-vbc' alt='logo-vbc' />
                </div>
                <div className='d-flex justify-content-center align-items-center px-5 mt-auto bar-progress-end'>
                    <ProgressBar
                        width="100%"
                        height="15px"
                        rect
                        fontColor="white"
                        percentage="100"
                        rectPadding="1px"
                        rectBorderRadius="20px"
                        trackPathColor="transparent"
                        bgColor="#1f1f36"
                        defColor={{
                            fair: 'orangered',
                            good: 'yellow',
                            excellent: '#e42c50',
                            poor: 'red',
                          }}
                    />
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
      userInfo: state.userInfo,
      pathWebsite: state.pathWebsite.data
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      cleanDataUser: () => dispatch({ type: "CLEAN_STORE"})
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(Loading);