import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import userInfo from "./saveAccount.js"
import voteList from "./voteList.js"
import ballotInfo from "./ballotInfo.js"
import pointAll from "./pointAll"
import infoWinner from "./infoWinner"
import listVote from "./listVote"
import pathWebsite from "./pathWebsite"
import chartData from "./chartData"
import drawList from "./drawList"
import bka from "./bka"
import listWinner from "./listWinner"
import listCheckin from "./listCheckin"

const appReducer = combineReducers({
  userInfo,
  voteList,
  ballotInfo,
  pointAll,
  infoWinner,
  listVote,
  pathWebsite,
  chartData,
  drawList,
  bka,
  listWinner,
  listCheckin
});


const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined;
    storage.removeItem('persist:root')
  }
  return appReducer(state, action);
};

export default rootReducer;