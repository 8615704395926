import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import MilkteaNav from "components/Navbars/MIilkNavbar"
import TopVote from "./topVote"
import { Container } from "reactstrap"
import Footer from 'components/Footers/MilkTea'

const Result = (props) => {
    const match = props.match;
    const getBallotDetail = props.getBallotDetail;
    const getPointAll = props.getPointAll;
    const getListVote = props.getListVote;

    React.useEffect(() => {
        if (match.params) {
            if (match.params.id && match.params.id2) {
                getBallotDetail(match.params.id, match.params.id2);
                getPointAll(match.params.id,match.params.id2);
                getListVote(match.params.id,match.params.id2);
            }
        }
    }, [match, getBallotDetail, getPointAll, getListVote])


    return (
        <>
            <MilkteaNav />
            <Container className='py-5'>
                <TopVote />
            </Container>
            <Footer/>
        </>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        getBallotDetail: (id, id2) => dispatch({ type: "GET_BALLOT_DETAIL", id: id, id2: id2 }),
        getPointAll: (id, id2) => dispatch({ type: "GET_POINT_ALL", id: id, id2: id2 }),
        getListVote: (id, id2) => dispatch({ type: "GET_LIST_VOTE", id: id, id2: id2 }),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Result));