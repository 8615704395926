import React from "react";
import { connect } from "react-redux";
import Form from "./form";
import "./index.css";
const Yearend = (props) => {
  const bka = props.bka;
  React.useEffect(()=>{
  if(bka){
    setName(bka.name)
    setCourse(bka.course)
  }
  },[bka])
  const [name, setName] = React.useState('')
  const [course, setCourse] = React.useState('')
    return (
      <div className='position-relative contain-card-form'>
        <>
          <div className="bka-background">
            {name && course ? <div className='bg-white round px-4 py-2 wrapper-bka'> <p className='font-weight-bold text-bka mb-0'>{name + ' - ' + course}</p> </div>: <div className='bg-white round px-4 py-2 wrapper-bka'> <p className='font-weight-bold text-bka mb-0'>{name}</p> </div>}
          </div>
            <Form />
          </>
      </div>
    );
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    pathWebsite: state.pathWebsite.data,
    bka: state.bka.data
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    cleanDataUser: () => dispatch({ type: "CLEAN_STORE" })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Yearend);
