const style = theme => ({
    avatar: {
        borderRadius: "50%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        height: "100px",
        width: "100px",
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
})
export default style;