const initialState = { data: [], dataFull: []}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_DRAW_LIST":
      return {
        ...state
      }
    case "SAVE_DRAW_LIST_SUCCESS":
      return {
        ...state,
        data: action.data,
        dataFull: action.dataFull
      }
    case "SAVE_DRAW_LIST_FAILURE":
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
