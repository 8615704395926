import { SAVE_LIST_WINNER_SUCCESS } from "actions";
import { SAVE_LIST_CHECKIN } from "actions";
import { SAVE_LIST_WINNER } from "actions";
import { SAVE_LIST_CHECKIN_SUCCESS } from "actions";
import { SAVE_BKA, SAVE_BKA_SUCCESS } from "actions";
import {
  GET_BALLOT_DETAIL,
  GET_BALLOT_DETAIL_FAILURE, GET_BALLOT_DETAIL_SUCCESS,
  GET_INFO_WINNER,
  GET_INFO_WINNER_FAILURE, GET_INFO_WINNER_SUCCESS,
  GET_LIST_VOTE,
  GET_LIST_VOTE_FAILURE, GET_LIST_VOTE_SUCCESS, GET_POINT_ALL,
  GET_POINT_ALL_FAILURE, GET_POINT_ALL_SUCCESS, GET_VOTE_LIST,
  GET_VOTE_LIST_FAILURE,
  GET_VOTE_LIST_SUCCESS,
  SAVE_DRAW_LIST,
  SAVE_DRAW_LIST_SUCCESS, SAVE_PATH_WEBSITE,
  SAVE_PATH_WEBSITE_SUCCESS, SAVE_USER_INFO, SAVE_USER_INFO_SUCCESS
} from "actions/index";
import axios from "axios";
import { LINK_API } from "constants/API";
import { call, put, select, takeLatest } from 'redux-saga/effects';
//Get token from redux store
const getToken = (state) => state.userInfo.data.token

// Save draw list
function* saveDrawList(payload) {
  yield put({ type: SAVE_DRAW_LIST_SUCCESS, data: payload.data, dataFull: payload.dataFull })
}


// Save User Info
function* saveUserInfo(data) {
  yield put({ type: SAVE_USER_INFO_SUCCESS, data: data.data, pass: data.pass })
}
function* saveListWinner(data) {
  yield put({ type: SAVE_LIST_WINNER_SUCCESS, data: data.data })
}
//SAVE BKA CHECK IN
function* saveBKA(data) {
  // console.log(data)
  yield put({ type: SAVE_BKA_SUCCESS, data: data.data, pass: data.pass })
}

function* saveListCheckin(data) {
  yield put({ type: SAVE_LIST_CHECKIN_SUCCESS, data: data.data, pass: data.pass })

}
// Save path website
function* savePathWebsite(data) {
  yield put({ type: SAVE_PATH_WEBSITE_SUCCESS, data: data.data })
}

//get all vote list
function* getVoteList(id) {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchVoteList, tokenInfo)
    const data = response.data.data.reverse();
    yield put({ type: GET_VOTE_LIST_SUCCESS, data: data, responseCode: 0 })
  } catch (error) {
    yield put({ type: GET_VOTE_LIST_FAILURE, responseCode: 0 })
  }
}
function fetchVoteList(checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_VOTE_LIST
  })
}

//get ballot detail
function* getBallotDetail(id) {
  try {
    const tokenInfo = yield select(getToken)
    const response = id.id !== "undefined" ? yield call(fetchBallotDetail, id.id, tokenInfo) : yield call(fetchBallotDetail, id.id2, tokenInfo)
    const data = response.data
    if (id.id2) {
      const response2 = yield call(fetchBallotDetail, id.id2, tokenInfo)
      yield put({ type: GET_BALLOT_DETAIL_SUCCESS, data: data.data, data2: response2.data.data, responseCode: data.responseCode })
    } else {
      yield put({ type: GET_BALLOT_DETAIL_SUCCESS, data: data.data, responseCode: data.responseCode })
    }
  } catch (error) {
    yield put({ type: GET_BALLOT_DETAIL_FAILURE, responseCode: 0 })
  }
}
function fetchBallotDetail(id, checktoken) {
  return axios({
    method: 'GET',
    url: LINK_API.GET_BALLOT_DETAIL + id
  })
}
//get all point
function* getPointAll(id) {
  try {
    const tokenInfo = yield select(getToken)
    const response = id.id !== 'undefined' ? yield call(fetchPointAll, id.id, tokenInfo) : yield call(fetchPointAll, id.id2, tokenInfo)

    const data = response.data.data
    let series = [{
      name: "Lượt bình chọn",
      data: [],
    }];
    let categories = [];
    for (let i = 0; i < data.length; i++) {
      series[0].data.push(data[i].point);
      categories.push(data[i].name);
    }
    if (id.id2) {
      const response2 = yield call(fetchPointAll, id.id2, tokenInfo)
      yield put({ type: GET_POINT_ALL_SUCCESS, data: data, data2: response2.data.data, responseCode: data.responseCode })
    } else {
      yield put({ type: GET_POINT_ALL_SUCCESS, data: data, responseCode: data.responseCode })
    }
    yield put({ type: "GET_CHART_DATA_SUCCESS", series: series, categories: categories, responseCode: data.responseCode })
  } catch (error) {
    yield put({ type: GET_POINT_ALL_FAILURE, responseCode: 0 })
  }
}
function fetchPointAll(id, checktoken) {
  return axios({
    method: 'GET',
    url: LINK_API.GET_POINT_ALL + id
  })
}

//get info winner
function* getInfoWinner(id) {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchInfoWinner, id.id, tokenInfo)
    const data = response.data
    yield put({ type: GET_INFO_WINNER_SUCCESS, data: data.data, responseCode: data.responseCode })
  } catch (error) {
    yield put({ type: GET_INFO_WINNER_FAILURE, responseCode: 0 })
  }
}
function fetchInfoWinner(id, checktoken) {
  return axios({
    method: 'GET',
    url: LINK_API.GET_INFO_WINNER + id
  })
}

//Get list vote
//get info winner
function* getListVote(id) {
  try {
    const tokenInfo = yield select(getToken)
    const response = id.id !== 'undefined' ? yield call(fetchListVote, id.id, tokenInfo) : yield call(fetchListVote, id.id2, tokenInfo);
    const data = response.data
    if (id.id2) {
      const response2 = yield call(fetchListVote, id.id2, tokenInfo)
      yield put({ type: GET_LIST_VOTE_SUCCESS, data: data.data, data2: response2.data.data, responseCode: data.responseCode })
    } else {
      yield put({ type: GET_LIST_VOTE_SUCCESS, data: data.data, responseCode: data.responseCode })
    }
  } catch (error) {
    yield put({ type: GET_LIST_VOTE_FAILURE, responseCode: 0 })
  }
}
function fetchListVote(id, checktoken) {
  return axios({
    method: 'GET',
    url: LINK_API.GET_LIST_VOTING + id
  })
}
//root saga
export default function* rootSaga() {
  yield takeLatest(SAVE_USER_INFO, saveUserInfo)
  yield takeLatest(SAVE_LIST_WINNER, saveListWinner)
  yield takeLatest(SAVE_LIST_CHECKIN, saveListCheckin)

  yield takeLatest(SAVE_BKA, saveBKA)
  yield takeLatest(GET_VOTE_LIST, getVoteList)
  yield takeLatest(GET_BALLOT_DETAIL, getBallotDetail)
  yield takeLatest(GET_POINT_ALL, getPointAll)
  yield takeLatest(GET_INFO_WINNER, getInfoWinner)
  yield takeLatest(GET_LIST_VOTE, getListVote)
  yield takeLatest(SAVE_PATH_WEBSITE, savePathWebsite)
  yield takeLatest(SAVE_DRAW_LIST, saveDrawList)
}