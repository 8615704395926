const initialState = { data: ''}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_PATH_WEBSITE":
      return {
        ...state
      }
    case "SAVE_PATH_WEBSITE_SUCCESS":
      return {
        ...state,
        data: action.data
      }
    case "SAVE_PATH_WEBSITE_FAILURE":
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
export default reducer
