/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import BallotPage from "views/ballotPage/index.js"
import MilkTeaPage from "views/milkteaPage/index.js"
import EmployeeTeaPage from "views/peoplePage/index.js"
import { config as i18nextConfig } from 'translation/index.jsx';
import { persistor, store } from 'store/store.js'
import ResultPage from "views/resultPage/index.js"
import YearEnd from "views/yearend";
import ResultPageYearEnd from "views/winnerYE/index"
import ResultPageYearEndV2 from "views/winner_v2/index"
import ResultPageYearEndMobile from "views/winnerYE/phone"

import BKA from "views/bka"

i18next.init(i18nextConfig);

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <BrowserRouter>
                        <I18nextProvider i18n={i18next}>
                            <Switch>
                                <Route path="/admin" render={props => <AdminLayout {...props} />} />
                                <Route path="/auth" render={props => <AuthLayout {...props} />} />
                                <Route path="/voting/:id" render={props => <BallotPage {...props} />} />
                                <Route path="/milktea/:id/:id2" render={props => <MilkTeaPage {...props} />} />
                                <Route path="/employTea/:id" render={props => <EmployeeTeaPage {...props} />} />
                                <Route path="/result/:id/:id2" render={props => <ResultPage {...props} />} />
                                <Route path="/vbc/yep2021" render={props => <YearEnd {...props} />} />
                                <Route path="/vbc/result" render={props => <ResultPageYearEnd {...props} />} />
                                <Route path="/bka/result" render={props => <ResultPageYearEndV2 {...props} />} />
                                <Route path="/vbc/mobileresult" render={props => <ResultPageYearEndMobile {...props} />} />
                                <Route path="/bka" render={props => <BKA {...props} />} />
                                <Redirect from="/" to="/auth/register" />
                            </Switch>
                        </I18nextProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        )
    }
}
export default hot(App);




